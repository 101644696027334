import {
    downloadSiScoringManual,
    eraseSiScoringQuestion,
    getAllScoring,
    getSiScoringPdfData,
    setSiScoringOpenAnswer,
    uploadPdfContent
} from "../services/siscoring";
import {getLastElementOr, showlog} from "../utils";

export const loadAllScorings = isAlive => getAllScoring( isAlive);

export const loadSiScoringPdfData = id => {
    return (
        getSiScoringPdfData( id )
            .then( data => {
                /*
                {
                    "companyInfo": {
                        "id": 82,
                        "name": null,
                        "vat": "02270970235",
                        "address": "",
                        "companyName": "Gruppo Manara S.p.A. - Pet & Food",
                        "city": "",
                        "province": "",
                        "postalCode": "",
                        "phoneNumber": "",
                        "email": ""
                    },
                    "releaseDate": "2022-05-09T11:17:35.75",
                    "srEvaluatioName": "Scoring #10                                       ",
                    "srReportsPerformance": [
                        {
                            "thematicId": 20,
                            "thematicName": "Ambiente",
                            "performance": 67
                        },
                        ...
                        {
                            "thematicId": 28,
                            "thematicName": "Innovazione",
                            "performance": 56
                        }
                    ],
                    "srReportsRatingCriteria": {
                        "rating": 47,
                        "environmental": 0,
                        "social": 0,
                        "governance": 0
                    },
                    "srReportsSdgList": [
                        {
                            "srSdgId": 10,
                            "srSdgName": "REDUCED INEQUALITIES",
                            "performance": 0
                        },
                        ...
                        {
                            "srSdgId": 17,
                            "srSdgName": "PARTNERSHIP FOR THE GOALS",
                            "performance": 0
                        }
                    ]
                }
                 */
                showlog("dati chiamata");
                showlog( data );

                let releaseDate = {"releaseDate" : data.releaseDate}

                let startThID = 35;
                if( data.srReportsPerformance.some(th => (th.thematicId === 20)) ) {
                    startThID = 20;
                }

                let dto = {
                    companyName:    data.companyInfo.companyName,
                    envAmbPerc:     data.srReportsPerformance.filter(th => th.thematicId === (startThID + 0) ).reduce( getLastElementOr, { performance: 0 } ).performance / 100,
                    envECPerc:      data.srReportsPerformance.filter(th => th.thematicId === (startThID + 1) ).reduce( getLastElementOr, { performance: 0 } ).performance / 100,
                    envRRPerc:      data.srReportsPerformance.filter(th => th.thematicId === (startThID + 2) ).reduce( getLastElementOr, { performance: 0 } ).performance / 100,
                    socCond:        data.srReportsPerformance.filter(th => th.thematicId === (startThID + 3) ).reduce( getLastElementOr, { performance: 0 } ).performance / 100,
                    socRCC:         data.srReportsPerformance.filter(th => th.thematicId === (startThID + 4) ).reduce( getLastElementOr, { performance: 0 } ).performance / 100,
                    socSQ:          data.srReportsPerformance.filter(th => th.thematicId === (startThID + 5) ).reduce( getLastElementOr, { performance: 0 } ).performance / 100,
                    govSTR:         data.srReportsPerformance.filter(th => th.thematicId === (startThID + 6) ).reduce( getLastElementOr, { performance: 0 } ).performance / 100,
                    govEt:          data.srReportsPerformance.filter(th => th.thematicId === (startThID + 7) ).reduce( getLastElementOr, { performance: 0 } ).performance / 100,
                    govInn:         data.srReportsPerformance.filter(th => th.thematicId === (startThID + 8) ).reduce( getLastElementOr, { performance: 0 } ).performance / 100,
                    env: data.srReportsRatingCriteria.environmental / 100,
                    soc: data.srReportsRatingCriteria.social / 100,
                    gov: data.srReportsRatingCriteria.governance / 100,
                    autoRating: data.srReportsRatingCriteria.rating / 100,
                    sdg:
                        data.srReportsSdgList
                            .sort( (x, y) => {
                                if( x.srSdgId < y.srSdgId ) return -1;
                                return 1;
                            })
                            .map( singleSDG => singleSDG.performance )
                            .map( singleSDG => {
                                if( singleSDG < 0 ) return 'NA';
                                return singleSDG;
                            }),
                    companyInfo: Object.assign(data.companyInfo, releaseDate)
                }

                return dto;


            })
            .catch(e => {
                return Promise.reject( e );
            })
    );

}



export const sendPdfContent = (html, evaluation_id = 0) => uploadPdfContent( html, evaluation_id );


export const saveSiScoringOpenAnswer = ( ratingID, questionID, questionStructureId, openAnswer ) => {
    let dto = { score: parseFloat( openAnswer ) || 0 };
    return setSiScoringOpenAnswer( ratingID, questionID, questionStructureId, dto );
}



export const saveSiScoringFatherAndSons = () => {

}

export const deleteSiScoringQuestion = questionID => eraseSiScoringQuestion( questionID );


export const downloadSiScoringUserManual = () => {
    let lang = localStorage.getItem("language"); //.substr(0, 2).toLowerCase();
    return downloadSiScoringManual( lang );
};
