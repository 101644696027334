import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {WaitingOrNodataMessage} from "../components/waiting-or-nodata-message";
import {PATH_PREFIX, TABS} from "../conf/siroad";
import PageTitle from "../components/PageTitle";
import {Card, CardBody, CardHeader, Collapse, Input, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import {cloneObject, errorlog, getLastElementOr, onlyNotNull, renderlog, showlog} from "../utils";
import {COLOR_POLICY} from "../conf/color-policy";
import {SIRATING_TABS} from "../conf/sirating";
import {loadAllFlatQuestions} from "../business-logic/rating";
import "../style/edit-question.css";
import GotoTopComponent from "../components/goto-top-component";
import {translate} from "../components/i18n/translate-function";
import SiRatingLogoDarkText from "../assets/images/SiRating_logo_dark_text_2.svg";
import {FullEditQuestionModal} from "../components/edit-question-modal_v2";
import ActionButton from "../components/save-button";
import SiRatingQuestionInfoModal from "../components/si-rating-question-info-modal";
import VersionModal from "../components/area/super-admin/version-modal";


class EditQuestionPage extends Component {

	state = {
		activeTab: 1,
		activeThematic : [],
		activeSubThematic: []
	}

	constructor( props ) {
		super( props );

		this.loadData = this.loadData.bind( this );
	}

	render() {

		renderlog("EditQuestionPage", this.state, this.props, COLOR_POLICY.select)

		let content = (

			(!!this.state.data && this.state.data.length > 0)
				? (
					this.state.tematiche
						.map( thematic => (
							<Card className="mb-0" key={ thematic.id }>
								<CardHeader
									className={"tooltip-icon-cursor"+ (!!thematic.open ? (" open-question cp-"+ this.state.activeTab) : "")}
									onClick={ clickEvent => {

										showlog("thematic", thematic)

										this.setState({
											...this.state,
											tematiche:
												this.state.tematiche.map( d => {
													if ( d.id === thematic.id ) d.open = !thematic.open;
													return d;
												}),

											// potrebbe essere inutile
											activeThematic: [
												...this.state.activeThematic.filter( thID => thID !== thematic.code ),
												(!!thematic.open) ? thematic.code : null
											].filter( onlyNotNull )


										})
									}}
								>
									<div className={"editable-q-a-element"} id={"question-"+ thematic.id}>
										<div className={""}>
											<code><strong>{ thematic.code }</strong></code>&nbsp;&nbsp;{ thematic.name }<br/>
										</div>
										<i
										className="mdi mdi-chevron-double-down"
										style={{color: "#1188ff"}}
									/>
									</div>
								</CardHeader>
								<Collapse isOpen={ !!thematic.open }>
									<CardBody>


											{
												[
													...new Map(
														this.state.data
															.filter( q => q.thematic.code === thematic.code  )

															// .filter( q => this.state.activeThematic.includes( q.thematic.code )  )
															// .filter( q => this.state.activeSubThematic.includes( q.subThematic.code )  )
															.map( oggetto => [oggetto.subThematic.name, oggetto] )
													).values()
												]
												.map( (ch, chIndex) => {
													// showlog("aaa", this.state.data.filter( q => q.thematic_code === this.state.activeThematic  ) )


														/*
														<li className={"editable-q-a-element editable-single-answer cpa-"+ this.state.activeTab} id={"choiche-"+ ch.id} key={ch.id +"."+ chIndex}>
															<div className={""}>{ch.subThematic.code +` ( ${ch.subThematic.name} )`}</div>
														</li> */

														if(!!ch.subThematic.name){

															return (

																<Card className="mb-0" key={ ch.id }>
																	<CardHeader
																		className={"tooltip-icon-cursor"+ (!!ch.open ? (" open-question cp-"+ this.state.activeTab) : "")}
																		onClick={ clickEvent => {

																			showlog("vediamo")
																			showlog( ch )


																			this.setState({
																				...this.state,
																				themes:
																					this.state.themes.map( d => {
																						if ( d.id === ch.subThematic.id ) d.open = !ch.subThematic.open;
																						return d;
																					}),

																				// potrebbe essere inutile
																				activeSubThematic: [
																					...this.state.activeSubThematic.filter( thID => thID !== ch.subThematic.code ),
																					(!!ch.subThematic.open) ? ch.subThematic.code : null
																				].filter( onlyNotNull )


																			})
																		}}
																	>
																		<div className={"editable-q-a-element"} id={"question-"+ ch.id}>
																			<div className={""}>
																				<code><strong>{ ch.subThematic.code }</strong></code>&nbsp;&nbsp;{ ch.subThematic.name }<br/>
																			</div>
																			<i
																				className="mdi mdi-chevron-down"
																				style={{color: "#1188ff"}}
																			/>
																		</div>
																	</CardHeader>
																	<Collapse isOpen={ !!ch.subThematic.open }>
																		<CardBody>

																			<ul className={"editable-answer-container cp-"+ this.state.activeTab}>

																				{
																					this.state.data
																						.filter( q => q.subThematic.code === ch.subThematic.code  )
																						.sort((a, b) => {
																							if( parseFloat(a.code.split("-")[1]) < parseFloat(b.code.split("-")[1]) ) return -1;
																							return 1;
																						})


																						// .filter( q => this.state.activeThematic.includes( q.thematic.code )  )
																						// .filter( q => this.state.activeSubThematic.includes( q.subThematic.code )  )

																						.map( (ch, chIndex) => {
																							// showlog("aaa", this.state.data.filter( q => q.thematic_code === this.state.activeThematic  ) )
																							return (

																								<Card style={{marginBottom:"0px"}}>
																									<CardHeader
																										className={"tooltip-icon-cursor"+ (!!ch.open ? (" open-question cp-"+ this.state.activeTab) : "")}
																										onClick={ clickEvent => {
																											showlog("ch", ch)
																											this.setState({
																												...this.state,
																												data:
																													this.state.data.map( d => {
																														if ( d.id === ch.id ) d.open = !ch.open;
																														return d;
																													})
																											})
																										}}
																									>
																										<div className={"editable-q-a-element"} id={"question-"+ ch.id}>
																											<div className={""}>
																												<code><strong>{ ch.code }</strong></code>&nbsp;&nbsp;{ ( localStorage.getItem("language")==="IT-it" ) ? (ch.text.it) : ( ( localStorage.getItem("language")==="EN-gb" )  ) ? (ch.text.en) : "" }<br/>
																												<span className={"score-label no-margin-left"}>{translate("Max Score")}: <strong>{ch.scoreMax}</strong></span>
																												<span className={"score-label"}>{translate("Version")}: <strong>{ch.version}</strong></span>
																											</div>
																											<div className={"editable-q-a-comander-container siscoring"}>
																												<i
																													className="mdi mdi-information-outline tooltip-icon-cursor"
																													onClick={ clickEvent => {
																														clickEvent.stopPropagation();
																														showlog("iddddd", ch)
																														this.setState({
																															...this.state,
																															questionInfoModal: ch
																														})
																													}}
																												/>
																												<i
																													className="mdi mdi-pencil tooltip-icon-cursor"
																													onClick={ clickEvent => {
																														clickEvent.stopPropagation();
																														this.setState({
																															...this.state,
																															questionModal: ch
																														})
																													}}
																												/>
																											</div>
																										</div>
																									</CardHeader>
																									<Collapse isOpen={ !!ch.open }>
																										<CardBody>


																											<ActionButton
																												buttonText={
																													<React.Fragment>
																														<i className={"mdi mdi-plus-circle"}/>
																														<span>{ translate("add_choiche") }</span>
																													</React.Fragment>
																												}
																												promise={ () => {
																													return (new Promise((onEnd, onFail) => {

																														this.setState({
																																...this.state,
																																questionModal: this.state.fullData.reduce( getLastElementOr, {choiches: []} ).choiches.reduce( getLastElementOr, {} ),
																																isNewQuestion: true,
																																isChoiche: true,
																																fatherQuestion: ch
																															},
																															() => {
																																onEnd();
																															}
																														)
																													}))
																												}}
																												onResult={ result => {
																												}}
																												onError={ error => {
																													errorlog("Salvataggio edit domanda", error)
																												}}
																												className={'srtool-edit-modal-button srtool-edit-modal-add-button'}
																											/>

																											<ul className={"editable-answer-container cp-"+ this.state.activeTab}>
																												{
																													ch.choiches
																														.map( (choice, chIndex) => (
																															<li className={"editable-q-a-element editable-single-answer cpa-"+ this.state.activeTab} id={"choiche-"+ choice.id} key={ choice.id +"."+ chIndex }>
																																<div className={""}>{ choice.text.it +` ( ${ choice.score } )` }</div>
																																<i
																																	className="mdi mdi-pencil tooltip-icon-cursor"
																																	onClick={ clickEvent => {
																																		clickEvent.stopPropagation();
																																		this.setState({
																																			...this.state,
																																			questionModal: choice,
																																			isChoiche: true,
																																			isNewQuestion: false
																																		})
																																	}}
																																/>
																															</li>
																														))
																												}
																											</ul>
																										</CardBody>
																									</Collapse>
																								</Card>


																								// <div className={"editable-q-a-element"} id={"question-"+ ch.id}>
																								// 	<div className={""}>
																								// 		<code><strong>{ ch.code }</strong></code>&nbsp;&nbsp;{ ch.text.it }<br/>
																								// 		<span className={"score-label no-margin-left"}>Punteggio Massimo: <strong>{ch.scoreMax}</strong></span>
																								// 	</div>
																								// 	<div className={"editable-q-a-comander-container siscoring"}>
																								// 		<i
																								// 			className="mdi mdi-information-outline tooltip-icon-cursor"
																								// 			onClick={ clickEvent => {
																								// 				clickEvent.stopPropagation();
																								// 				showlog("iddddd", ch)
																								// 				this.setState({
																								// 					...this.state,
																								// 					questionInfoModal: ch
																								// 				})
																								// 			}}
																								// 		/>
																								// 		<i
																								// 			className="mdi mdi-pencil tooltip-icon-cursor"
																								// 			onClick={ clickEvent => {
																								// 				clickEvent.stopPropagation();
																								// 				this.setState({
																								// 					...this.state,
																								// 					questionModal: ch
																								// 				})
																								// 			}}
																								// 		/>
																								// 	</div>
																								// </div>
																							)
																						})
																				}

																			</ul>

																		</CardBody>
																	</Collapse>
																</Card>

															)


														}else{

															return(


																<ul className={"editable-answer-container cp-"+ this.state.activeTab}>
																{
																	this.state.data
																		.filter( q => q.thematic.name === ch.thematic.name)
																		.filter( q => q.subThematic.code === ch.subThematic.code  )
																		.sort((a, b) => {
																			if( parseFloat(a.code.split("-")[1]) < parseFloat(b.code.split("-")[1]) ) return -1;
																			return 1;
																		})

																		// .filter( q => this.state.activeThematic.includes( q.thematic.code )  )
																		// .filter( q => this.state.activeSubThematic.includes( q.subThematic.code )  )

																		.map( (ch, chIndex) => {
																			// showlog("aaa", this.state.data.filter( q => q.thematic_code === this.state.activeThematic  ) )
																			return (

																				<Card style={{marginBottom:"0px"}}>
																					<CardHeader
																						className={"tooltip-icon-cursor"+ (!!ch.open ? (" open-question cp-"+ this.state.activeTab) : "")}
																						onClick={ clickEvent => {
																							this.setState({
																								...this.state,
																								data:
																									this.state.data.map( d => {
																										if ( d.id === ch.id ) d.open = !ch.open;
																										return d;
																									})
																							})
																						}}
																					>
																						<div className={"editable-q-a-element"} id={"question-"+ ch.id}>
																							<div className={""}>
																								<code><strong>{ ch.code }</strong></code>&nbsp;&nbsp;{ ( localStorage.getItem("language")==="IT-it" ) ? (ch.text.it) : ( ( localStorage.getItem("language")==="EN-gb" )  ) ? (ch.text.en) : "" }<br/>
																								<span className={"score-label no-margin-left"}>{translate("Max Score")}: <strong>{ch.scoreMax}</strong></span>
																								<span className={"score-label"}>{translate("Version")}: <strong>{ch.version}</strong></span>
																							</div>
																							<div className={"editable-q-a-comander-container siscoring"}>
																								<i
																									className="mdi mdi-information-outline tooltip-icon-cursor"
																									onClick={ clickEvent => {
																										clickEvent.stopPropagation();
																										showlog("iddddd", ch)
																										this.setState({
																											...this.state,
																											questionInfoModal: ch
																										})
																									}}
																								/>
																								<i
																									className="mdi mdi-pencil tooltip-icon-cursor"
																									onClick={ clickEvent => {
																										clickEvent.stopPropagation();
																										this.setState({
																											...this.state,
																											questionModal: ch
																										})
																									}}
																								/>
																							</div>
																						</div>
																					</CardHeader>
																					<Collapse isOpen={ !!ch.open }>
																						<CardBody>


																							<ActionButton
																								buttonText={
																									<React.Fragment>
																										<i className={"mdi mdi-plus-circle"}/>
																										<span>{ translate("add_choiche") }</span>
																									</React.Fragment>
																								}
																								promise={ () => {
																									return (new Promise((onEnd, onFail) => {

																										this.setState({
																												...this.state,
																												questionModal: this.state.fullData.reduce( getLastElementOr, {choiches: []} ).choiches.reduce( getLastElementOr, {} ),
																												isNewQuestion: true,
																												isChoiche: true,
																												fatherQuestion: ch
																											},
																											() => {
																												onEnd();
																											}
																										)
																									}))
																								}}
																								onResult={ result => {
																								}}
																								onError={ error => {
																									errorlog("Salvataggio edit domanda", error)
																								}}
																								className={'srtool-edit-modal-button srtool-edit-modal-add-button'}
																							/>

																							<ul className={"editable-answer-container cp-"+ this.state.activeTab}>
																								{
																									ch.choiches
																										.map( (choice, chIndex) => (
																											<li className={"editable-q-a-element editable-single-answer cpa-"+ this.state.activeTab} id={"choiche-"+ choice.id} key={ choice.id +"."+ chIndex }>
																												<div className={""}>{ choice.text.it +` ( ${ choice.score } )` }</div>
																												<i
																													className="mdi mdi-pencil tooltip-icon-cursor"
																													onClick={ clickEvent => {
																														clickEvent.stopPropagation();
																														this.setState({
																															...this.state,
																															questionModal: choice,
																															isChoiche: true,
																															isNewQuestion: false
																														})
																													}}
																												/>
																											</li>
																										))
																								}
																							</ul>
																						</CardBody>
																					</Collapse>
																				</Card>


																				// <div className={"editable-q-a-element"} id={"question-"+ ch.id}>
																				// 	<div className={""}>
																				// 		<code><strong>{ ch.code }</strong></code>&nbsp;&nbsp;{ ch.text.it }<br/>
																				// 		<span className={"score-label no-margin-left"}>Punteggio Massimo: <strong>{ch.scoreMax}</strong></span>
																				// 	</div>
																				// 	<div className={"editable-q-a-comander-container siscoring"}>
																				// 		<i
																				// 			className="mdi mdi-information-outline tooltip-icon-cursor"
																				// 			onClick={ clickEvent => {
																				// 				clickEvent.stopPropagation();
																				// 				showlog("iddddd", ch)
																				// 				this.setState({
																				// 					...this.state,
																				// 					questionInfoModal: ch
																				// 				})
																				// 			}}
																				// 		/>
																				// 		<i
																				// 			className="mdi mdi-pencil tooltip-icon-cursor"
																				// 			onClick={ clickEvent => {
																				// 				clickEvent.stopPropagation();
																				// 				this.setState({
																				// 					...this.state,
																				// 					questionModal: ch
																				// 				})
																				// 			}}
																				// 		/>
																				// 	</div>
																				// </div>
																			)
																		})



																}
																</ul>
															)

														}

												}

																	)
											}



									</CardBody>
								</Collapse>
							</Card>

						))
				)
				: (
					<WaitingOrNodataMessage
						waiting={ !this.state.data }
						fullHeight={true}
						nodataMessage={"Nessuna Domanda"}
					/>
				)

		);

		// vecchio content che tornava solo le domande (senza raggruppamento in tematiche e sottotematiche)
		// let content2 = (
		//
		// 	(!!this.state.data && this.state.data.length > 0)
		// 		? (
		// 			this.state.data
		// 				.map( question => (
		// 					<Card className="mb-0" key={ question.id }>
		// 						<CardHeader
		// 							className={"tooltip-icon-cursor"+ (!!question.open ? (" open-question cp-"+ this.state.activeTab) : "")}
		// 							onClick={ clickEvent => {
		// 								this.setState({
		// 									...this.state,
		// 									data:
		// 										this.state.data.map( d => {
		// 											if ( d.id === question.id ) d.open = !question.open;
		// 											return d;
		// 										})
		// 								})
		// 							}}
		// 						>
		// 							<div className={"editable-q-a-element"} id={"question-"+ question.id}>
		// 								<div className={""}>
		// 									<code><strong>{ question.code }</strong></code>&nbsp;&nbsp;{ question.text.it }<br/>
		// 									<span className={"score-label no-margin-left"}>Punteggio Massimo: <strong>{question.scoreMax}</strong></span>
		// 								</div>
		// 								<div className={"editable-q-a-comander-container siscoring"}>
		// 									<i
		// 										className="mdi mdi-information-outline tooltip-icon-cursor"
		// 										onClick={ clickEvent => {
		// 											clickEvent.stopPropagation();
		// 											showlog("iddddd", question)
		// 											this.setState({
		// 												...this.state,
		// 												questionInfoModal: question
		// 											})
		// 										}}
		// 									/>
		// 									<i
		// 										className="mdi mdi-pencil tooltip-icon-cursor"
		// 										onClick={ clickEvent => {
		// 											clickEvent.stopPropagation();
		// 											this.setState({
		// 												...this.state,
		// 												questionModal: question
		// 											})
		// 										}}
		// 									/>
		// 								</div>
		// 							</div>
		// 						</CardHeader>
		// 						<Collapse isOpen={ !!question.open }>
		// 							<CardBody>
		//
		//
		// 								<ActionButton
		// 									buttonText={
		// 										<React.Fragment>
		// 											<i className={"mdi mdi-plus-circle"}/>
		// 											<span>{ translate("add_choiche") }</span>
		// 										</React.Fragment>
		// 									}
		// 									promise={ () => {
		// 										return (new Promise((onEnd, onFail) => {
		//
		// 											this.setState({
		// 													...this.state,
		// 													questionModal: this.state.fullData.reduce( getLastElementOr, {choiches: []} ).choiches.reduce( getLastElementOr, {} ),
		// 													isNewQuestion: true,
		// 													isChoiche: true,
		// 													fatherQuestion: question
		// 												},
		// 												() => {
		// 													onEnd();
		// 												}
		// 											)
		// 										}))
		// 									}}
		// 									onResult={ result => {
		// 									}}
		// 									onError={ error => {
		// 										errorlog("Salvataggio edit domanda", error)
		// 									}}
		// 									className={'srtool-edit-modal-button srtool-edit-modal-add-button'}
		// 								/>
		//
		// 								<ul className={"editable-answer-container cp-"+ this.state.activeTab}>
		// 									{
		// 										question.choiches
		// 											.map( (ch, chIndex) => (
		// 												<li className={"editable-q-a-element editable-single-answer cpa-"+ this.state.activeTab} id={"choiche-"+ ch.id} key={ question.id +"."+ chIndex }>
		// 													<div className={""}>{ ch.text.it +` ( ${ ch.score } )` }</div>
		// 													<i
		// 														className="mdi mdi-pencil tooltip-icon-cursor"
		// 														onClick={ clickEvent => {
		// 															clickEvent.stopPropagation();
		// 															this.setState({
		// 																...this.state,
		// 																questionModal: ch,
		// 																isChoiche: true,
		// 																isNewQuestion: false
		// 															})
		// 														}}
		// 													/>
		// 												</li>
		// 											))
		// 									}
		// 								</ul>
		// 							</CardBody>
		// 						</Collapse>
		// 					</Card>
		// 				))
		// 		)
		// 		: (
		// 			<WaitingOrNodataMessage
		// 				waiting={ !this.state.data }
		// 				fullHeight={true}
		// 				nodataMessage={"Nessuna Domanda"}
		// 			/>
		// 		)
		//
		// );


		return (
			<React.Fragment>
				<PageTitle
					breadCrumbItems={[
						{label: 'SI Rating', path: '' + PATH_PREFIX + '/sirating/' + translate("edit-question")},
						{label: translate("Edit Questions"), active: true}
					]}
					title={
						<React.Fragment>
							<img
								src={ SiRatingLogoDarkText }
								style={{
									marginRight:'10px',
									height: "60px",
									float: "left",
									marginTop: "10px"
								}}
							/>

							<p style={{float:"left", marginTop: "27px",
								marginBottom: "-23px",
								fontSize: "14px",cursor:"pointer",height:"0px"}}
							   onClick={clickEvent => {
							   	this.setState({
									versionModal: true
								})
							   }
							   }
							>
								v. {(localStorage.getItem("siRatingVersion")).trim()} </p>

						</React.Fragment>
					}
				/>


				{
					!!this.state.versionModal
					? (
						<VersionModal
							tool={"SI Rating-2"}
							onClose={ closeEvent => {
								this.setState({
									...this.state,
									versionModal: false
								})
							}}/>
						)
							:
					null
				}


				{
					!!this.state.questionModal
						? (
							<FullEditQuestionModal
								avoidToolbar={ true }
								question={ this.state.questionModal }
								fatherQuestion={ this.state.fatherQuestion }
								isNew={ !!this.state.isNewQuestion }
								isChoiche={ !!this.state.isChoiche }
								tab={ this.state.activeTab }
								themes={ this.state.themes }
								isGL={ this.state.modalIsGL }
								isSR={ true }
								onClose={ toggleEvent => {
									let questionsTmp = cloneObject(this.state.fullData);
									if( !!toggleEvent ) this.loadData( questionsTmp );
									else {
										this.setState({
											...this.state,
											questionModal: null,
											modalIsGL: null,
											isChoiche: null,
											isNewQuestion: false,
											fatherQuestion: null
										})
									}
								}}
							/>
						)
						: null
				}


				{
					(!!this.state.questionInfoModal)
						?
						(
							<SiRatingQuestionInfoModal
								id={ this.state.questionInfoModal.id }
								code={this.state.questionInfoModal.code}
								onClose={ closeEvent => {
										this.setState({
											...this.state,
											questionInfoModal: null
										})
									}
								}
							/>
						)
						:
						null
				}

				<Card>
					<CardHeader>

						<Nav tabs>
							{
								SIRATING_TABS
									.map((tab, index) => {

										let colorClassInfo =
											Object.keys(TABS)
												.map(tabKey => TABS[tabKey])
												// .filter( tab => (parseInt(tab.id, 10) +1) === this.state.activeTab )
												.filter(t => t.siratingId === tab.id)
												.reduce(getLastElementOr, {className: ""})
												.className
										;

										return (
											<NavItem key={index}>
												<NavLink
													id={tab.id}
													href="#"
													className={
														// classnames({ active: this.state.activeTab === tab.id })
														[
															colorClassInfo
														]
															.join(" ")
													}
													onClick={() => {

														this.setState({
																...this.state,
																activeTab: tab.id,
																data: null
															},
															() => {
																this.loadData();
															}
														);
													}}>
													<i
														className={classnames(
															tab.icon,
															'd-lg-none',
															'd-block',
															'mr-1'
														)}
													/>
													<span className="d-none d-lg-block">
                                                        {tab.name}
                                                    </span>
												</NavLink>
											</NavItem>
										);

									})
							}

							<div className={"search-container"}>
								<Input
									type={"text"}
									placeholder={"cerca"}
									className={"rating-name-input"}
									onChange={ changeEvent => {
										let searchText = changeEvent.target.value;

										/*
										showlog(
											this.state.fullData
												.filter( question => (!!question && !!question.text && !!question.text.it))
												.filter( question => (

													Object.keys( question.text )
														.map( lan => question.text[ lan ] )
														.map( lanText => (lanText.toLowerCase().indexOf( searchText.toLowerCase() ) !== -1))
														.some( match => !!match )

													||

													question.choiches
														.some( ch => (
															Object.keys( ch.text )
																.map( lan => ch.text[ lan ] )
																.map( lanText => (lanText.toLowerCase().indexOf( searchText.toLowerCase() ) !== -1))
																.some( match => !!match )
														))

												))
										);
										*/

										if( !!searchText ) {
											this.setState({
												...this.state,
												data:
													this.state.fullData
														.filter( question => (!!question && !!question.text && !!question.text.it))
														.filter( question => (

															Object.keys( question.text )
																.map( lan => question.text[ lan ] )
																.map( lanText => (lanText.toLowerCase().indexOf( searchText.toLowerCase() ) !== -1))
																.some( match => !!match )

															||

															question.choiches
																.some( ch => (
																	Object.keys( ch.text )
																		.map( lan => ch.text[ lan ] )
																		.map( lanText => (lanText.toLowerCase().indexOf( searchText.toLowerCase() ) !== -1))
																		.some( match => !!match )
																))

														))
											})
										}
										else {
											this.setState({
												...this.state,
												data: this.state.fullData
											})
										}

									}}
								/>
							</div>
						</Nav>


						<TabContent activeTab={this.state.activeTab}>
							{
								SIRATING_TABS
									.map((tab, index) => {
										return (
											<TabPane
												tabId={tab.id}
												key={index}
											>

												<CardBody>

													<ActionButton
														buttonText={
															<React.Fragment>
																<i className={"mdi mdi-plus-circle"}/>
																<span>{ translate("new_question") }</span>
															</React.Fragment>
														}
														promise={ () => {

															return (new Promise((onEnd, onFail) => {

																this.setState({
																	...this.state,
																	questionModal: this.state.fullData.reduce( getLastElementOr, null),
																	isNewQuestion: true
																},
																	() => {
																		onEnd();
																	}
																)


															}))

														}}
														onResult={ result => {
														}}
														onError={ error => {
															errorlog("Salvataggio edit domanda", error)
														}}
														className={'srtool-edit-modal-button srtool-edit-modal-add-button'}
													/>

													{ content }
												</CardBody>


											</TabPane>
										)
									})
							}

							<GotoTopComponent/>

						</TabContent>

					</CardHeader>
				</Card>

			</React.Fragment>
		);
	}

	componentDidMount() {
		this.loadData();
	}

	loadData( questionList ) {
		return (

			Promise.resolve()

				.then( () => {
					return loadAllFlatQuestions( this.state.activeTab );
				})

				.then( list => {

					showlog("lidsta",list)

					list =
						list
							.sort((a, b) => {
								if( a.code < b.code ) return -1;
								return 1;
							})
							.map( q => {

								if( !!questionList ) {
									let clonedQuestion =
										questionList
											.filter( ql => ql.id === q.id )
											.reduce( getLastElementOr, { open: false } )
									;
									q.open = !!clonedQuestion.open;
									if (!!q?.subThematic) q.subThematic.open = clonedQuestion?.subThematic?.open || false
								}

								return q;
							})
					;



					return (

						new Promise((success, fail) => {
							this.setState({
								...this.state,
								data: list,
								fullData: list,
								tematiche:[
									...new Map(
										list
											.map( row => {
												if( !!row.thematic && !!row.thematic.name ) {
													return [
                                                        // key
														row?.thematic?.id,
                                                        // obj to return
                                                        {
                                                            ...row?.thematic,
                                                            open: (this.state.tematiche || [])
                                                            	.find(tmtc => tmtc?.id === row?.thematic?.id)
                                                            	?.open || false
                                                        }
													];
												}
												else {
													return []
												}
											})
									)
										.values()
								],
								themes:
									[
										...new Map(
											list
												.map( row => {
													if( !!row.subThematic && !!row.subThematic.name ) {
														return [row.subThematic.id, row.subThematic];
													}
													else {
														if( !!row.thematic && !!row.thematic.name ) {
															return [];
														}
													}
												})
										)
										.values()
									].filter(onlyNotNull)
								,
								questionModal: null,
								modalIsGL: null,
								isChoiche: null,
								isNewQuestion: false,
								fatherQuestion: null
							},
								() => {
									success();
								}
							);
						})

					);

				})

				.catch(e => {
					errorlog("caricamento piatto delle domande", e);
					return Promise.reject( e );
				})

		);
	}

}

export default withRouter(EditQuestionPage);
