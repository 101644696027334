import React from 'react';
import {role} from "../env";
import {showlog} from "../utils/dev-utils";
import {
    aboutRoutes,
    adminAreaActiveServicesRoute,
    adminAreaCollectsRoute,
    adminAreaEupaRoute,
    adminAreaSIRatingRoute,
    adminAreaUsersRoute,
    adminDashboardRoutes,
    authRoutes,
    consulerDashboardRoutes,
    consultantAreaCompaniesRoute,
    contactusRoutes, cookiePolicyRoutes,
    credentialsRoutes,
    dashboardRoutes,
    editPasswordRoutes,
    emojPageRoutes,
    genderGapMenu,
    genderGapPMMenu,
    genderGapPMRatingConfiguration,
    genderGapRatingPageRoute,
    griAdminAreaCollectsRoute,
    griAdminAreaUsersRoute,
    griManagerAreaTreeRoute,
    griManagerDashboardRoutes,
    griRequirementsFilter,
    notFoundRoute,
    operatorDashboardRoutes,
    permissionPageRoutes,
    powerBiSiratingRoute,
    privacyPolicyRoutes,
    projectManagerAreaActiveServicesRoute,
    publicEmailOnlyTextRoute,
    publicTestRoute,
    rootRoute,
    siratingAdminColorPolicyStatusLegendRoute,
    siratingAdminEvalutaionsMenu,
    siratingAdminEvalutaionsRoute,
    siratingAdminSingleEvalutaionRoute,
    siratingChartsRoutes,
    siratingEvaluationReadOnlyConfigurationPage,
    siratingSectorQuestionLinksRoute,
    siscoringMenu,
    SiScoringPdfPageRouting,
    siscoringPMMenu,
    siscoringRatingPageRoute,
    SRToolTestRoute,
    superAdminAreaActiveServicesRoute,
    superAdminAreaCompaniesRoute,
    superAdminAreaPdfParametersRoute,
    superAdminAreaSIRatingEvalutationRoute,
    superAdminAreaSIRatingMenuRoute,
    superAdminAreaSIRatingPerimetralRoute,
    superAdminAreaSIRatingRoute,
    superAdminAreaUsersRoute,
    superAdminAssignConsultantRoute,
    superAdminDashboardMenuRoutes,
    superAdminDashboardRoutes,
    superAdminLeadingRoute,
    supportRoutes,
    test,
    testEditQuestionRoutes,
    testPageRoutes,
    testPowerBiPageRoutes,
    welcomeRoutes, entraAuthenticationRoute
} from "./all-routes";
import {refreshToken} from "../services/auth-service";


// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
let allRoutes = [
    rootRoute
    , test

    , welcomeRoutes
    , credentialsRoutes
    , testPowerBiPageRoutes
    , powerBiSiratingRoute   // /test-pbi-sirating

    , dashboardRoutes
    , authRoutes
    , entraAuthenticationRoute
    , testPageRoutes
    , permissionPageRoutes
    , emojPageRoutes
    , supportRoutes
    , privacyPolicyRoutes
    ,cookiePolicyRoutes
    , aboutRoutes
    , contactusRoutes
    , editPasswordRoutes
    , siratingChartsRoutes

    , adminAreaUsersRoute                           // /admin/users
    // , griAdminAreaCollectsRoute                     // /srtool/collects                        ---- rimozione srTool scadenza GRI
    // , griAdminAreaTreeRoute                         // /srtool/collect/:id
    , griManagerAreaTreeRoute                       // /srtool/collect/:id
    , consultantAreaCompaniesRoute                  // /consuler/companies
    , griAdminAreaUsersRoute                        // /srtool/users
    , siratingAdminEvalutaionsRoute                 // /sirating-admin/evaluations
    , siratingAdminEvalutaionsMenu                  // /sirating-admin/evaluations
    , siratingAdminSingleEvalutaionRoute            // /sirating-admin/evaluation/:id
    , siratingAdminColorPolicyStatusLegendRoute     // /evaluations-legend
    , superAdminAreaUsersRoute                      // /super-admin/users
    , superAdminLeadingRoute                        // //super-admin/organizzations
    , superAdminAreaCompaniesRoute                  // /super-admin/companies
    , superAdminAreaActiveServicesRoute             // /super-admin/active-services
    , adminAreaActiveServicesRoute                  // /admin/active-services
    , projectManagerAreaActiveServicesRoute         // /project-manager/active-services
    , superAdminAreaPdfParametersRoute              // /super-admin/si-rating/evaluations/report-parameters/:id
    , superAdminAreaSIRatingRoute                   // /super-admin/si-rating
    , superAdminAssignConsultantRoute               // /consuler/assignto/:id
    , superAdminAreaSIRatingEvalutationRoute        // /si-rating/evaluation/:id
    , superAdminAreaSIRatingPerimetralRoute         // /si-rating/perimetral/:id
    , adminAreaCollectsRoute                        // /admin/collects
    , adminAreaEupaRoute                            // /sirating-admin/eupa/:id
    , siratingEvaluationReadOnlyConfigurationPage    // /super-admin/configuration/evaluation/:id

    // dashboards
    , superAdminDashboardRoutes         // /super-admin-dashboard
    // , superAdminRatingDashboardRoutes   // /super-admin-rating-dashboard
    , superAdminDashboardMenuRoutes     // menu group
    , adminDashboardRoutes              // /srtool/admin-dashboard
    //, siratingAdminDashboardRoutes      // /sirating-admin-dashboard
    , consulerDashboardRoutes           // /consuler-dashboard
    , operatorDashboardRoutes           // /operator-dashboard
    // , griAdminDashboardRoutes           // /srtool-admin-dashboard
    , griManagerDashboardRoutes         // /srtool-manager-dashboard
    , griRequirementsFilter
    , testEditQuestionRoutes
    // , editQuestionRoutes                // /sirating/edit-question

    , publicTestRoute
    , publicEmailOnlyTextRoute              // public/only-text-email
    , SRToolTestRoute


    , siratingSectorQuestionLinksRoute

    // , signupPageRoute
    , siscoringMenu
    , siscoringPMMenu
    , siscoringRatingPageRoute
    , SiScoringPdfPageRouting

    , genderGapMenu
    , genderGapPMMenu
    , genderGapRatingPageRoute
    , genderGapPMRatingConfiguration


    , notFoundRoute
];

let authProtectedRoutes = [
    rootRoute
];



// menu laterale
showlog("%cRuolo "+ localStorage.getItem("role"), "color: #ff0;");
switch ( localStorage.getItem("role") ) {


    case role.PROJECT_MANAGER.name:     // -----------------------------------------------------------------------------

        showlog("%cMenu per il ruolo "+ role.PROJECT_MANAGER.name, "background: #ff0; color: #444;");

        // rotte dal menu
        // quelle che non sono presenti qui non
        // vengono mostrate nel menu
        authProtectedRoutes = [
            ...authProtectedRoutes
            //, superAdminDashboardRoutes
            , superAdminAreaCompaniesRoute
            , superAdminLeadingRoute

            , superAdminAreaUsersRoute
            , superAdminAreaSIRatingRoute
            //, powerBiSiratingRoute

            , siscoringPMMenu
             // , griAdminAreaCollectsRoute           ---- rimozione srTool scadenza GRI
            , genderGapPMMenu


            // , projectManagerAreaActiveServicesRoute
        ]

        break;


    case role.ADMIN.name:   // -----------------------------------------------------------------------------------------

        showlog("%cMenu per il ruolo "+ role.ADMIN.name, "background: #ff0; color: #444;");
        authProtectedRoutes = [
            ...authProtectedRoutes
            // , adminDashboardRoutes
            ,adminAreaUsersRoute
            // , superAdminAreaActiveServicesRoute
           // , siratingAdminDashboardRoutes
            // , adminAreaSIRatingRoute
            , siratingAdminEvalutaionsRoute
            // , siratingAdminEvalutaionsMenu
            , siscoringMenu


            // , griAdminAreaCollectsRoute         ---- rimozione srTool scadenza GRI
            , genderGapMenu


            // , adminAreaActiveServicesRoute
        ];

        break;

    case role.SI_Scoring_Admin.name:   // -----------------------------------------------------------------------------------------

        showlog("%cMenu per il ruolo "+ role.SI_Scoring_Admin.name, "background: #ff0; color: #444;");
        authProtectedRoutes = [
            ...authProtectedRoutes
            // , adminDashboardRoutes
            // ,adminAreaUsersRoute
            // , superAdminAreaActiveServicesRoute
            // , siratingAdminDashboardRoutes
            // , adminAreaSIRatingRoute
            // , siratingAdminEvalutaionsRoute
            // // , siratingAdminEvalutaionsMenu
            , siscoringMenu
            //
            //
            // , griAdminAreaCollectsRoute
            // , genderGapMenu


            // , adminAreaActiveServicesRoute
        ];

        break;

    case role.Parità_Di_Genere_Admin.name:   // -----------------------------------------------------------------------------------------

        showlog("%cMenu per il ruolo "+ role.Parità_Di_Genere_Admin.name, "background: #ff0; color: #444;");
        authProtectedRoutes = [
            ...authProtectedRoutes
            // , adminDashboardRoutes
            // ,adminAreaUsersRoute
            // , superAdminAreaActiveServicesRoute
            // , siratingAdminDashboardRoutes
            // , adminAreaSIRatingRoute
            // , siratingAdminEvalutaionsRoute
            // // , siratingAdminEvalutaionsMenu
            // , siscoringMenu

            //
            // , griAdminAreaCollectsRoute
            , genderGapMenu


            // , adminAreaActiveServicesRoute
        ];

        break;

    case role.SUPER_ADMIN.name:   // -----------------------------------------------------------------------------------

        showlog("%cMenu per il ruolo "+ role.SUPER_ADMIN.name, "background: #ff0; color: #444;");
        authProtectedRoutes = [
            ...authProtectedRoutes
            // , adminDashboardRoutes

            // , superAdminDashboardMenuRoutes
            // , superAdminDashboardRoutes
            // , superAdminRatingDashboardRoutes

            , superAdminAreaUsersRoute

            , superAdminAreaCompaniesRoute
            , superAdminAreaSIRatingMenuRoute
            // , griAdminAreaCollectsRoute      ---- rimozione srTool scadenza GRI
            , siscoringMenu
            // , adminAreaSIRatingRoute

            // , siratingAdminEvalutaionsRoute
            // , superAdminAreaActiveServicesRoute
        ];

        break;


    case role.CONSULTANT.name:  // -------------------------------------------------------------------------------------

        showlog("%cMenu per il ruolo "+ role.CONSULTANT.name, "background: #ff0; color: #444;");
        authProtectedRoutes = [
            ...authProtectedRoutes
            , consulerDashboardRoutes
            , adminAreaUsersRoute
            // , superAdminAreaCompaniesRoute
            // , superAdminAreaActiveServicesRoute
            , griAdminAreaCollectsRoute
            , adminAreaSIRatingRoute
        ];

        break;


    case role.SRTool_ADMIN.name:   // ----------------------------------------------------------------------------------

        showlog("%cMenu per il ruolo "+ role.SRTool_ADMIN.name, "background: #ff0; color: #444;");
        authProtectedRoutes = [
            ...authProtectedRoutes
            // , griAdminDashboardRoutes
             , griAdminAreaUsersRoute
            // , griAdminAreaCollectsRoute     ---- rimozione srTool scadenza GRI
        ];

        break;


    case role.ASSURANCER.name:     // ----------------------------------------------------------------------------------

        showlog("%cMenu per il ruolo "+ role.ASSURANCER.name, "background: #ff0; color: #444;");
        authProtectedRoutes = [
            ...authProtectedRoutes
            // , griAdminDashboardRoutes
            // , griAdminAreaUsersRoute
            // , griAdminAreaCollectsRoute   ---- rimozione srTool scadenza GRI
        ];
        break;

    case role.SRTool_MANAGER.name:     // ------------------------------------------------------------------------------

        showlog("%cMenu per il ruolo "+ role.SRTool_MANAGER.name, "background: #ff0; color: #444;");
        authProtectedRoutes = [
            ...authProtectedRoutes
            // , griManagerDashboardRoutes
            // , griManagerAreaTreeRoute
            // , griAdminAreaCollectsRoute       ---- rimozione srTool scadenza GRI
        ];

        break;


    case role.SR_ADMIN.name:    // -------------------------------------------------------------------------------------

        showlog("%cMenu per il ruolo "+ role.SR_ADMIN.name, "background: #ff0; color: #444;");
        authProtectedRoutes = [
            // ...authProtectedRoutes
            //, siratingAdminDashboardRoutes
            siratingAdminEvalutaionsRoute
            // , siratingAdminEvalutaionsMenu
            //, siratingAdminColorPolicyStatusLegendRoute
        ];

        break;


    case role.SR_CONSULER.name:     // ---------------------------------------------------------------------------------

        showlog("%cMenu per il ruolo "+ role.SR_CONSULER.name, "background: #ff0; color: #444;");
        authProtectedRoutes = [
            ...authProtectedRoutes
            , siratingAdminEvalutaionsRoute
            //, siratingAdminColorPolicyStatusLegendRoute
        ];

        break;


    case role.ARB_OPERATOR.name:    // ---------------------------------------------------------------------------------

        showlog("%cMenu per il ruolo "+ role.ARB_OPERATOR.name, "background: #ff0; color: #444;");
        authProtectedRoutes = [
            ...authProtectedRoutes
            //, operatorDashboardRoutes
            , superAdminAreaSIRatingRoute
            //, powerBiSiratingRoute
            , siscoringPMMenu
            // , griAdminAreaCollectsRoute   ---- rimozione srTool scadenza GRI
            , genderGapPMMenu
        ];

        break;


    default:

        showlog("public route");
        showlog( localStorage.getItem("role") );

        authProtectedRoutes = [
            publicTestRoute
            , publicEmailOnlyTextRoute
            , SRToolTestRoute
            , privacyPolicyRoutes
            ,cookiePolicyRoutes
            // signupPageRoute
        ];

}

const allFlattenRoutes = flattenRoutes(allRoutes);

showlog("allFlattenRoutes");
showlog(allFlattenRoutes);



// avoid back ----------------------------------------------------------------------------------------------------------
window.history.pushState(null, null, document.URL);
window.addEventListener('popstate', function () {
    window.history.pushState(null, null, document.URL);
});



// console.log("check");


showlog("startUp refreshToken");
refreshToken();


// avoid refresh -------------------------------------------------------------------------------------------------------
let down = {};
let up = {};
window.addEventListener('keydown', e => {
    down[e.keyCode] = true;

    if ((e.which || e.keyCode) == 116 ) e.preventDefault();

    if(
        (down[17] && down[82]) ||   // CTRL + ENTER
        (down[91] && down[82]) ||   // CMD_LEFT + ENTER
        (down[93] && down[82])      // CMD_RIGHT + ENTER
    ) {
        e.preventDefault();
        e.stopPropagation();
        showlog("refresh bloccato");
    }

});

window.addEventListener('keyup', e => {
    up[e.keyCode] = true;

    if(
        (up[17] && up[82]) ||   // CTRL + ENTER
        (up[91] && up[82]) ||   // CMD_LEFT + ENTER
        (up[93] && up[82])      // CMD_RIGHT + ENTER
    ) {
        e.preventDefault();
        e.stopPropagation();
        showlog("refresh sbloccato");
        down = {};
        up = {};
    }
    else {
        // sblocco del refresh
        down = {};
        up = {};
    }

})


export { allRoutes, authProtectedRoutes, allFlattenRoutes };
