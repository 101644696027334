// // import React from "react";
// // export const EntraAuthenticationPage = () => {
// //     console.log('ciaaaaaaoooooo')
// //     return (<div>ciaooooooooooo</div>)
// // }
// // // @flow
// import React from 'react';
// import {useParams} from "react-router-dom";
// import {sendExternalAuthToken} from "../../../services/request-manager";
//
//
//
//
// export default const EntraAuthenticationPage = () => {
//
//     state = {
//     }
//
//     constructor(props) {
//         super(props);
//     }
//
//     async componentDidMount() {
//         console.log('ciaaaaaaoooooo')
//         console.log('window.location.href', window.location.href)
//         // console.log('window.location.href', useParams())
//         await sendExternalAuthToken({})
//     }
//
//     render() {
//
//     }
//
//     return (<div>ciaooooooooooo</div>)
//
// };
//
//

// import React from "react";
// export const EntraAuthenticationPage = () => {
//     console.log('ciaaaaaaoooooo')
//     return (<div>ciaooooooooooo</div>)
// }
// // @flow
import React, {useEffect} from 'react';
import {Redirect, useParams} from "react-router-dom";
import {sendExternalAuthToken} from "../../../services/request-manager";
import {showlog} from "../../../utils";
import {Cookies} from "react-cookie";
import jwtDecode from "jwt-decode";
import {PATH_PREFIX} from "../../../conf/siroad";
import Spinner from "../../../components/Spinner";
import {translate} from "../../../components/i18n/translate-function";


export const EntraAuthenticationPage = () => {

    const {token = ''} = useParams();
    // showlog('params', params);
    //
    // const {token = ''} = params;
    useEffect(() => {
        showlog('token found', token);
        sendExternalAuthToken(token)
            .then(result => {
                    let now = new Date();
                    const cookies = new Cookies();
                    cookies.set(
                        "user",
                        btoa(JSON.stringify(result)),
                        {
                            path: "/",
                            expires: new Date((parseInt(jwtDecode(result.jwtToken).exp, 10) * 1000) + (364 * 24 * 3600000))  // scadenza: 1 anno
                        }
                    );

                    showlog('result', result);

                    // localStorage
                    localStorage.setItem("jwtToken", result?.jwtToken);
                    localStorage.setItem("id", result?.id);
                    localStorage.setItem("role", result?.role);
                    localStorage.setItem("company_id", result?.company_id);
                    localStorage.setItem("company_name", result?.company_name);
                    localStorage.setItem("companyName", result?.companyName);
                    localStorage.setItem("title", result?.title);
                    localStorage.setItem("email", result?.email);
                    localStorage.setItem("firstName", result?.firstName);
                    localStorage.setItem("lastName", result?.lastName);
                    localStorage.setItem("siRoadVersion", result?.platformVersions?.siRoad);
                    localStorage.setItem("siRatingVersion", result?.platformVersions?.siRating);
                    localStorage.setItem("siScoringVersion", result?.platformVersions?.siScoring);
                    localStorage.setItem("srToolVersion", result?.platformVersions?.srTool);
                    localStorage.setItem("paritaGenereVersion", result?.platformVersions?.paritaGenere);
                    localStorage.setItem("Platform", result?.platform);


                    if (!result?.evaluationId) return Promise.reject('missing evaluationID')
                    let newLocation =
                        window.location.href
                            .split("/")
                            .filter((_, i) => i < 3)
                            .join("/")
                        + `/si-scoring/valutazione/${result?.evaluationId || result?.EvaluationId}`
                    ;
                    window.location.href = newLocation;
                    return <Redirect to={"" + PATH_PREFIX}/>;


                }
            )
            .catch(e => {
                showlog('error in sendExternalAuthToken()', e)
                let newLocation =
                        window.location.href
                            .split("/")
                            .filter((_, i) => i < 3)
                            .join("/")
                    //+ "/"
                ;
                window.location.href = newLocation;
                return <Redirect to={"" + PATH_PREFIX}/>;
            })
    }, []);


    return (
        <div style={{margin: 20}}>
            <Spinner color={"#fffa"} className="spinner-border-sm mr-1" />
            <span>
                {translate("EntraLoadMessage")}
            </span>
        </div>
    )
};


