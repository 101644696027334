import React from "react";
import { Step } from 'react-albus';


export const ZeroStep = props => (
	<Step
		id={ "zero" }
		render={ ({ next }) => (
			
			<React.Fragment>
				
				<div>Zero Step</div>
			
			</React.Fragment>
		
		)}
	/>
);
