import {GriValues_Get} from "./request-manager";


/**
 * Load raw entities
 *
 * @param parameter
 * @returns {*}
 */
export const getGriValues = parameter => {
	return GriValues_Get( parameter );
}
