import {
    API_ENDPOINT,
    API_PORT,
    errorMessage,
    HOST,
    LOGS_PORTALADDRESS,
    LOSG_PORTAL_TOKEN,
    PRODUCTION_HOST,
    STATUS_SUCCESS
} from "../env";
import "./interceptor";
import {beautifyLog, errorlog, showlog} from "../utils/dev-utils";
import {saveAs} from 'file-saver';
import {httpTimer} from "../utils/timer-utils";
import {getLastElementOr} from "../utils/array-utils";
import {translate} from "../components/i18n/translate-function";


const httpMethod = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH"
}


/**
 * Non c'è possibilità di cambiare l'header!!!!
 *
 * @param type
 * @param endpoint
 * @param body
 * @returns {Promise<any | never>}
 * @constructor
 */
const HttpRequest = (type, endpoint, body = "") => {

    let raw = JSON.stringify(body);
    let requestOptions = {
        method: type,
        headers: {
            "Content-Type": "application/json"
                // Origin: "8.8.8.8"
        },
        redirect: "follow",
        credentials: "include"
    };

    if (type !== httpMethod.GET) Object.assign(requestOptions, { body: raw });


    return (
        Promise.race([
            httpTimer.start(),
            fetch(HOST + API_PORT + API_ENDPOINT + "/" + endpoint, requestOptions)
                .then(result => {
                    if( result.headers.get("Content-Type").indexOf("json") !== -1 ) return result.json();
                    if( result.headers.get("Content-Type").indexOf("plain") !== -1 ) return result.text();
                    if( result.headers.get("Content-Type").indexOf("text") !== -1 ) return result.text();
                    if( result.headers.get("Content-Type").indexOf("octet-stream") !== -1 ) return result.blob();
                })
                // .then(result => result.json())
                .then(result => {

                    if (!result.status) {

                        /*
                        if (!!result.message && result.message.length > 0) {
                            throw result.message;
                        } else {
                            throw (
                                Object.keys(errorMessage)
                                .map(errorKind => errorMessage[errorKind])
                                .filter(e => e.code === result.code)
                                .map(e => e.msg)
                                .reduce(getLastElementOr, result.message)
                            );
                        }
                        */

                        // throw result.message;
                    }
                    return result;
                })
                .then(result => {
                    // if( result.headers.get("Content-Type").indexOf("json") !== -1 ) return result.data;
                    if( !!result.data ) return result.data;
                    return result;
                })
                .then(result => {
                    return result;
                })
                .catch(error => {
                    return Promise.reject(error);
                })
        ])
    )

}


const HttpDataRequest = (type, endpoint, data = "", headersObject) => {


    let requestOptions = {
        method: type,
        headers: Object.assign({
            // "Content-Type": "application/json"
            // Origin: "8.8.8.8"
        }, headersObject),
        redirect: "follow",
        credentials: "include"
    };

    if (type !== httpMethod.GET) Object.assign(requestOptions, { body: data });


    return (
        Promise.race([
            httpTimer.start(),
            fetch(HOST + API_PORT + API_ENDPOINT + "/" + endpoint, requestOptions)
            .then(result => result.json())
            .then(result => {

                if (!result.status) {

                    if (!!result.message && result.message.length > 0) {
                        throw result.message;
                    } else {
                        throw (
                            Object.keys(errorMessage)
                            .map(errorKind => errorMessage[errorKind])
                            .filter(e => e.code === result.code)
                            .map(e => e.msg)
                            .reduce(getLastElementOr, result.message)
                        );
                    }


                    // throw result.message;
                }
                return result;
            })
            .then(result => result.data)
            .then(result => {
                return result;
            })
            .catch(error => {
                // errorlog("HTTP", error);
                // showlog("ritorno catch alla request");
                return Promise.reject(error);
            })
        ])
    )

}


const HttpGetBlobRequest = endpoint => {
    let requestOptions = {
        method: httpMethod.GET,
        redirect: "follow",
        credentials: "include",
        exposedHeaders: '*'
    };
    return (
        fetch(HOST + API_PORT + API_ENDPOINT + "/" + endpoint, requestOptions)
        .then(result => result.blob())
        .catch(error => {
            errorlog("HttpRequest error", error);
            return Promise.reject(error);
        })
    );
}


/**
 *
 * @param endpoint
 * @param blob
 * @param fileName
 * @param obj
 * {
 *     name: "ciao",
 *     date: "3213213232",
 *     valore: 2,
 *     text: "abcabcabvca"
 * }
 * @returns {Promise<any>}
 * @constructor
 */
const HttpSingleBlobRequest = (endpoint, blob, fileName, obj) => {

    let formdata = new FormData();
    formdata.append("file", blob, fileName);
    if(!!obj) Object.keys(obj).forEach( campo => formdata.append(campo, obj[campo]) );


    let requestOptions = {
        method: httpMethod.POST,
        body: formdata,
        redirect: "follow",
        credentials: "include"
            //"Content-Type": "application/x-www-form-urlencoded"
    };

    return (
        fetch(HOST + API_PORT + API_ENDPOINT + "/" + endpoint, requestOptions)
        .then(result => result.json())
        .then(result => {
            // if (!result.status) throw result.message;
            return result;
        })
        .catch(error => {
            showlog("HttpSingleBlobRequest error", error);
            return Promise.reject(error);
        })
    );
}


const HttpFormAndBlobsRequest = (endpoint, data) => {

    let formdata = new FormData();

    if(!!data) Object.keys(data).forEach( campo => formdata.append(campo, data[campo]) );


    let requestOptions = {
        method: httpMethod.POST,
        body: formdata,
        redirect: "follow",
        credentials: "include"
            //"Content-Type": "application/x-www-form-urlencoded"
    };

    return (
        fetch(HOST + API_PORT + API_ENDPOINT + "/" + endpoint, requestOptions)
        .then(result => result.json())
        .then(result => {
            // if (!result.status) throw result.message;
            return result;
        })
        .catch(error => {
            showlog("HttpSingleBlobRequest error", error);
            return Promise.reject(error);
        })
    );
}


//***************************************ACCOUNT********************************************************************

export const WelcomePage_Get = (company_id) => {
    let endpoint = "Welcomepage/" + company_id;
    return HttpRequest(httpMethod.GET, endpoint);
}

export const Account_Get = () => {
    let endpoint = "accounts";
    return HttpRequest(httpMethod.GET, endpoint);
}


export const SelfAccount_Get = companyID => {
    // let endpoint = "accounts/self";
    let endpoint = `accounts/srtoolmanagers/${ companyID }`;
    return HttpRequest(httpMethod.GET, endpoint);
}


export const CompanyInsurers_Get = companyid => {
    let endpoint = "assurancers/company/" + companyid;
    return HttpRequest(httpMethod.GET, endpoint);
}

export const CollectInsurers_Get = collectid => {
    let endpoint = "assurancers/collection/" + collectid;
    return HttpRequest(httpMethod.GET, endpoint);
}


export const Insurer_DELETE = (insurerId, companyId) => {
    let endpoint = ["assurancers", insurerId, companyId].join("/");
    return HttpRequest(httpMethod.DELETE, endpoint);
}

export const Insurer_PUT = (collectId, insurerList) => {
    let endpoint = "assurancers/" + collectId;
    return HttpRequest(httpMethod.PUT, endpoint, insurerList);
}


export const AssuranceRequirement_PUT = (collectId, isRequired) => {
    let endpoint = "GriTimings/setIsAssuranceRequirement/" + collectId;
    return HttpRequest(httpMethod.PUT, endpoint, { isAssuranceRequirement: isRequired });
}


export const StartAssurance = (collectId, state) => {
    let endpoint = ["GriStructure", "timingStatus", collectId, state].join("/");
    return HttpRequest(httpMethod.POST, endpoint);
}



export const SetInformativeAssurance = (collectId, informativeId, payload) => {
    let endpoint = ["GriStructure", "informativeStatus", collectId, informativeId].join("/");
    return HttpRequest(httpMethod.PUT, endpoint, payload);
}

export const Account_Create = body => {
    let endpoint = "accounts";
    return HttpRequest(httpMethod.POST, endpoint, body);
}


export const Account_Update = (body, id) => {
    let endpoint = "accounts/" + id;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const Account_Delete = id => {
    let endpoint = "accounts/" + id;
    return HttpRequest(httpMethod.DELETE, endpoint);
}


/*
export const Login(body) {
	let endpoint = "accounts/authenticate"
	return (HttpRequest(httpMethod.POST, null, endpoint, body).then(result => {
		return result
	})
			.catch(error => {
				showlog("request manager error");
				showlog(error);
				return Promise.reject(error);
			})
	);
}
*/


//{"email":"enzobuffa@gmail.com"}
export const SendResetPassword = body => {
    let endpoint = "accounts/SendResetPassword";
    return HttpRequest(httpMethod.POST, endpoint, body);
}


export const SignupNewCustomer = body => {
    let endpoint = ["accounts", "register"].join("/");
    return HttpRequest(httpMethod.POST, endpoint, body);
}

export const PasswordSetting = body => {
    let endpoint = "accounts/PasswordSetting";
    return HttpRequest(httpMethod.POST, endpoint, body);
}


export const Account_EditPassword = user => {
    let endpoint = "accounts/updatePassword/" + user.id;
    return HttpRequest(httpMethod.PUT, endpoint, user);
}


export const Companies_Get = id => {
    let endpoint = "companies/" + id;
    return HttpRequest(httpMethod.GET, endpoint);
}


export const Companies_GetAddress = id => {
    let endpoint = "companies/address/" + id;
    return HttpRequest(httpMethod.GET, endpoint);
}


export const Companies_GetContacts = id => {
    let endpoint = "companies/contacts/" + id;
    return HttpRequest(httpMethod.GET, endpoint);
}


export const Companies_GetAll = organizzationId => {
    let endpoint = "companies/leading/" + (!!organizzationId ? organizzationId : 0);
    return HttpRequest(httpMethod.GET, endpoint);
}


export const ServiceActives_GetAll = () => {
    let endpoint = "serviceActives";
    return HttpRequest(httpMethod.GET, endpoint);
}

export const OrganizzationServiceActives_GetAll = () => {
    let endpoint = "serviceActives/leading";
    return HttpRequest(httpMethod.GET, endpoint);
}


export const ServiceActives_Create = body => {
    let endpoint = "serviceActives";
    return HttpRequest(httpMethod.POST, endpoint, body);
}


export const ServiceActives_Update = (body, id) => {
    let endpoint = "serviceActives/" + id;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const ServiceActives_Delete = id => {
    let endpoint = "serviceActives/" + id;
    return HttpRequest(httpMethod.DELETE, endpoint);
}


//*****************************************************************************************************************


export const GriTimings_Get = (id, isAlive = 1) => {
    let endpoint = ["GriTimings", id, isAlive].join("/");
    return HttpRequest(httpMethod.GET, endpoint);
}

export const GetSRToolLanguage = (level, id) => {
    let endpoint = `gristructure/content/${level}/${id}`;
    return HttpRequest(httpMethod.GET, endpoint);
}

export const GetSRToolGlLanguage = (level, id) => {
    let endpoint = `gristructure/guideline/${level}/${id}`;
    return HttpRequest(httpMethod.GET, endpoint);
}

export const GetSRToolRaccomandationLanguage = (level, id) => {
    let endpoint = `gristructure/recommendations/${level}/${id}`;
    return HttpRequest(httpMethod.GET, endpoint);
}

export const GetSRToolCompilationRequirementsLanguage = (level, id) => {
    let endpoint = `gristructure/compilationrequirements/${level}/${id}`;
    return HttpRequest(httpMethod.GET, endpoint);
}




export const PutSRToolLanguage = (level, id, body) => {
    let endpoint = `gristructure/content/${level}/${id}`;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}

export const PutSRToolLanguageGL = (level, id, body) => {
    let endpoint = `gristructure/guideline/${level}/${id}`;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}

export const PutSRToolRaccomandationLanguage = (level, id, body) => {
    let endpoint = `gristructure/recommendations/${level}/${id}`;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}

export const PutSRToolCompilationRequirementsLanguage = (level, id, body) => {
    let endpoint = `gristructure/compilationrequirements/${level}/${id}`;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}

export const GriTimings_Create = body => {
    let endpoint = "GriTimings";
    return HttpRequest(httpMethod.POST, endpoint, body);
}


export const GriTimings_CreateBy = (body, cid, valuesIncluded = 1) => {
    let endpoint = "GriTimings/duplication/" + cid + "/" + valuesIncluded;
    return HttpRequest(httpMethod.POST, endpoint, body);
}


export const GriTimings_Update = (body, id) => {
    let endpoint = "GriTimings/" + id;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const GriTimings_Delete = id => {
    let endpoint = "GriTimings/" + id;
    return HttpRequest(httpMethod.DELETE, endpoint);
}


export const GriStructure_Get = (timing_id, tabId, tmId, isAll) => {
    let endpoint = "GriStructure/" + timing_id + "/" + tabId + "/" + (!!tmId ? tmId : 0); // + "/0" ;

    endpoint += "/" + (!!isAll ? 1 : 0);
    endpoint += "/0";


    return HttpRequest(httpMethod.GET, endpoint);
}


export const GriStructure_GetValues = (timing_id, tabId, tmId, isAll) => {
    let endpoint = "GriStructure/" + timing_id + "/" + tabId + "/" + (!!tmId ? tmId : 0); //+"/1";

    endpoint += "/" + (!!isAll ? 1 : 0);
    endpoint += "/1";

    return HttpRequest(httpMethod.GET, endpoint);
}


export const GriStructure_EditMaterial = (body, timing_id, level) => {
    let endpoint = [
            "GriStructure",
            "editMaterial",
            timing_id,
            level
        ]
        .join("/");
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const GriStructure_EditManager = (body, timing_id, level) => {
    let endpoint = [
            "GriStructure",
            "editManager",
            timing_id,
            level
        ]
        .join("/");
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const GriStructure_EditRequirementTiming = (body, timing_id, level, requirement_id) => {
    let endpoint = [
            "GriStructure",
            "editRequirementTiming",
            timing_id,
            level,
            requirement_id
        ]
        .join("/");
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const GriStructure_editRequirementUnitMeasurement = (body, timing_id, level, requirement_id) => {
    let endpoint = [
            "GriStructure",
            "editRequirementUnitMeasurement",
            timing_id,
            level,
            requirement_id
        ]
        .join("/");
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const GriStructure_EditApplicability = (body, timing_id, level) => {
    let endpoint = [
            "GriStructure",
            "editApplicability",
            timing_id,
            level
        ]
        .join("/");
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const GriStructure_EditInformative = (body, timing_id, informative_id) => {
    let endpoint = [
            "GriStructure",
            "editInformative",
            timing_id,
            informative_id
        ]
        .join("/");
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const GriStructure_EditRequirementGoal = (body, timingId, level, reqId) => {
    let endpoint = [
            "GriStructure",
            "editGoal",
            timingId,
            level,
            reqId
        ]
        .join("/");
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


/*
export const GriStructure_EditRequirements = (body, timingId, level, reqId) => {
	let endpoint =
		[
			"GriStructure",
			"editRequirement",
			timingId,
			level,
			reqId
		]
		.join("/");
	return (
		HttpRequest(httpMethod.PUT, endpoint, body)
			.then(result => {
				return result
			})
			.catch(error => {
				showlog("request manager error");
				showlog(error);
				return Promise.reject(error);
			})
	);
}
*/

export const GriValues_SetRepeatingValues = (ms, fatherGriStructureId, nodeId, GriRepeatingValueId) => {

    let endpoint = [
        "GriValues",
        fatherGriStructureId,
        nodeId,
        GriRepeatingValueId
    ].join("/");
    return HttpRequest(httpMethod.PUT, endpoint, ms);
}


export const GriValues_GetRepeatingValues = (subRequirementId, fatherStructureId, areaId) => {
    let endpoint = [
        "GriValues",
        "GetRepeatingValues",
        fatherStructureId, // requisito padre
        subRequirementId, // sotto-requisito
        areaId
        // nodeId,
    ].join("/");
    return HttpRequest(httpMethod.GET, endpoint);
};

/**
 * potenzialmente la stessa di quella di sopra
 * @param structureId
 * @param repeatingValueId
 * @returns {Promise<*|SimpleCacheConfigurator.never>}
 * @constructor
 */
export const GriValues_GetData = (structureId, repeatingValueId) => {
    let endpoint = [
        "GriValues",
        structureId,
        // nodeId,
        // repeatingValueId
    ].join("/");
    return HttpRequest(httpMethod.GET, endpoint);
};

export const GriValues_GetPrevious = (structureId, previousYear) => {
    let endPoint = `GriValues/previousyears/${ structureId }`;
    return HttpRequest(httpMethod.GET, endPoint);
};


export const GriValues_Add = body => {
    let endpoint = "GriValues";
    return HttpRequest(httpMethod.POST, endpoint, body);
}


export const GriStructure_Confirm = data => {
    let endpoint = ["GriStructure", "confirm"].join("/");
    return HttpRequest(httpMethod.POST, endpoint, data);
}


export const GriValues_SetZone = data => {
    let endpoint = "GriValues";
    return HttpRequest(httpMethod.POST, endpoint, data);
}


export const GriInformative103_Get = (timing_id, chapter_id, informative_id) => {
    let endpoint = [
        "GriInformative103",
        timing_id,
        chapter_id,
        informative_id
    ].join("/");
    return HttpRequest(httpMethod.GET, endpoint);
}


export const GriInformative103_Update = (body, timing_id, informative_id) => {
    let endpoint = ["GriInformative103", timing_id, "0", informative_id].join("/");
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const GriInformative103_UpdateChapter = (body, timing_id, chapter_id) => {
    let endpoint = [
        "GriInformative103",
        timing_id,
        chapter_id,
        "0"
    ].join("/");
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const GriMaterialThematics_Get = timing_id => {
    let endpoint = "GriMaterialThematics/" + timing_id;
    return HttpRequest(httpMethod.GET, endpoint);
}


export const GriMaterialThematics_Create = body => {
    let endpoint = "GriMaterialThematics";
    return HttpRequest(httpMethod.POST, endpoint, body);
}


export const GriMaterialThematics_Update = (body, id) => {
    let endpoint = "GriMaterialThematics/" + id;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const GriMaterialThematics_Delete = id => {
    let endpoint = "GriMaterialThematics/" + id;
    return HttpRequest(httpMethod.DELETE, endpoint);
}


export const GriMaterialThematicInformatives_Get = materialthematic_id => {
    let endpoint = "GriMaterialThematicInformatives/" + materialthematic_id;
    return HttpRequest(httpMethod.GET, endpoint);
}


export const GriMaterialThematicInformatives_Add = body => {
    let endpoint = "GriMaterialThematicInformatives";
    return HttpRequest(httpMethod.POST, endpoint, body);
}


export const GriMaterialThematicInformatives_Edit = (body, id) => {
    let endpoint = "GriMaterialThematicInformatives/" + id;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const GriMaterialThematicInformatives_Delete = id => {
    let endpoint = "GriMaterialThematicInformatives/" + id;
    return HttpRequest(httpMethod.DELETE, endpoint);
}


export const GriAttachments_Get = timing_id => {
    let endpoint = "GriAttachments/" + timing_id;
    return HttpRequest(httpMethod.GET, endpoint);
}


export const GriAttachments_Download = id => {
    let endpoint = ["GriAttachments", "download", id].join("/");
    return HttpRequest(httpMethod.GET, endpoint);
}


export const GriAttachments_Delete = id => {
    let endpoint = ["GriAttachments", id].join("/");
    return HttpRequest(httpMethod.DELETE, endpoint);
}


export const Rating_Delete = id => {
    let endpoint = ["srevaluations", id].join("/");
    return HttpRequest(httpMethod.DELETE, endpoint);
}


export const GriValues_Set = (body, id) => {
    let endpoint = "Grivalues/" + id;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const GriMaterialThematicInformatives_griTimings_Get = (id) => {
    let endpoint = [
        "GriMaterialThematicInformatives",
        "griTimings",
        id
    ].join("/");

    return HttpRequest(httpMethod.GET, endpoint);
}


export const getAllGriMaterialThematicInformatives_Get = id => {
    let endpoint = [
        "GriMaterialThematicInformatives",
        "materialeinformatives",
        id
    ].join("/");

    return HttpRequest(httpMethod.GET, endpoint);
}


export const GriRepeatingValues_Update = (body, id) => {
    let endpoint = "GriRepeatingValues/" + id;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const GriRepeatingValues_Delete = id => {
    let endpoint = ["GriRepeatingValues", id].join("/");
    return HttpRequest(httpMethod.DELETE, endpoint);
}


export const ConsultantCompaniesAlloweds_Get = id => {
    let endpoint = "CompaniesAlloweds";
    if (!!id) endpoint += "/" + id;
    return HttpRequest(httpMethod.GET, endpoint);
}


/**
 *
 * @param body = { UserId: 1, CompanyId: 1 }
 * @returns {Promise<any | never | never>}
 * @constructor
 */
export const Companies_EditCurrentCompany = body => {
    let endpoint = "CompaniesAlloweds/editCurrentCompany";
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const CompaniesAlloweds_Update = body => {
    let endpoint = "CompaniesAlloweds";
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const GriValues_Get = parameters => {
    let endpoint = "GriValues/" + parameters;
    return HttpRequest(httpMethod.GET, endpoint);
}


export const Sectors_Get = company_id => {
    let endpoint = "SRSector/" + company_id;
    return HttpRequest(httpMethod.GET, endpoint);
}

export const SectorHistory_Get = company_id => {
    let endpoint = "SrSector/companySectorHistory/" + company_id;
    return HttpRequest(httpMethod.GET, endpoint);
}

export const EvaluationUsersRole_Get = (evaluation_id, type) => {
    let endpoint = "SrEvaluations/usersRole/" + evaluation_id + "/" + type;
    return HttpRequest(httpMethod.GET, endpoint);
}


export const Sectors_Put = (body, parameters, main = 0) => {
    let endpoint = "SRSector/" + parameters;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}

export const UsersRole_Put = (body, evaluation_id, type) => {
    let endpoint = "SrEvaluations/usersRole/" + evaluation_id + "/" + type;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const Evaluations_Get = (ratingType, isAlive) => {
    let endpoint = ["SrEvaluations", ratingType, isAlive].join("/");
    return HttpRequest(httpMethod.GET, endpoint);
}

export const SiScoringReport_Get = id => {
    let endpoint = ["SrReports", "SiScoringCardInfo", id].join("/");
    return HttpRequest(httpMethod.GET, endpoint);
}

export const SiScoringReport_POST = (content, evaluation_id) => {
    let endpoint = ["SrReports", "SiScoringCardInfoPdf"].join("/");
    return HttpRequest(httpMethod.POST, endpoint, {html: content, evaluation_id: evaluation_id });
}

export const SiScoringReport_Delete = id => {
    let endpoint = ["SrReports", "SiScoringCardInfoPdf", id].join("/");
    return HttpRequest(httpMethod.DELETE, endpoint);
}

export const SiScoringAnswer_POST = ( ratingID, questionID, questionStructureID, body ) => {
    // return Promise.resolve();
    let endpoint = [
        "SrAnswers",
        "open",
        // questionStructureID,
        ratingID,
        questionID
    ].join("/");
    return HttpRequest(httpMethod.PUT, endpoint, body);
}



export const EvaluationsDashboard_Get = () => {
    let endpoint = "SrEvaluations/dashboard";
    return HttpRequest(httpMethod.GET, endpoint);
}

export const getSuperAdminRatingDashboard = () => {
    let endpoint = "SrReports/getSuperAdminDashboard";
    return HttpRequest(httpMethod.GET, endpoint);
}


export const Evaluations_Add = body => {
    let endpoint = "SrEvaluations";
    return HttpRequest(httpMethod.POST, endpoint, body);
}


export const GenderGap_SAVE_Questions = ( ratingID, questionID, openAnswers ) => {
    let endpoint = ["SrAnswers", "quantitative", ratingID, questionID].join("/");
    return HttpRequest(httpMethod.PUT, endpoint, openAnswers);
}

export const GenderGap_SAVE_Preventive_Question = ( ratingID, questionID, choicheID ) => {
    let endpoint = ["SrAnswers", "preventive", ratingID, questionID, choicheID].join("/");
    return HttpRequest(httpMethod.PUT, endpoint);
}




// { "TipologiaCommerciale":"aaa", "Dimensioni":1}
export const Companies_Edit = (body, cid) => {
    let endpoint = "companies/" + cid;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const QuestionsStructure_Get = (ratingId, macroAreaId) => {
    let endpoint = "SrQuestionsStructure/" + ratingId + "/" + macroAreaId;
    return HttpRequest(httpMethod.GET, endpoint);
}


export const SectorQuestions_Get = () => {
    let endpoint = "SrQuestions/sector";
    return HttpRequest(httpMethod.GET, endpoint);
}

export const SectorQuestionsCompanies_Get = id => {
    let endpoint = [
        "SrQuestions",
        "company",
        id
    ].join("/");
    return HttpRequest(httpMethod.GET, endpoint);
}

export const putIsOnlyMainSector = (obj,id) => {
    let endpoint = [ "SrQuestions/SetOnlyMainSector/" + id ]
    return HttpRequest(httpMethod.PUT, endpoint, obj)
}

export const SectorQuestions_Create = body => {
    let endpoint = "SrQuestions/sector/";
    return HttpRequest(httpMethod.POST, endpoint, body);
}


// {"CompanyId":6,"ServiceId":1, "DateStart":"2021-01-01", "MonthsNumber":12}
export const SectorQuestions_Update = (body, id) => {
    let endpoint = "SrQuestions/sector/" + id;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const SectorQuestions_Delete = id => {
    let endpoint = "SrQuestions/sector/" + id;
    return HttpRequest(httpMethod.DELETE, endpoint);
}




export const DisableSectorQuestions_PUT = (id) => {
    let endpoint = "srquestions/sectorToggle/" + id;
    return HttpRequest(httpMethod.PUT, endpoint);
}


// { enable: true }    parameters = "valutazioneId/tematicaId/sottoTematicaId" // se manca la sottoTematica sostituire con zero
export const QuestionsStructure_Put = (body, parameters) => {
    let endpoint = "SrQuestionsStructure/" + parameters;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const editRatingName_Put = (id, body) => {
    let endpoint = "SrEvaluations/" + id;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const ToggleSingleQuestionStructure = (body, questionId) => {
    let endpoint = "SrQuestionsStructure/" + questionId;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const Thematics_GET = () => {
    let endpoint = "SrThematics";
    return HttpRequest(httpMethod.GET, endpoint);
}


export const ChoicheQuestions_Get = sr_question_id => {
    let endpoint = "SrQuestionsChoiche/question/" + sr_question_id;
    return HttpRequest(httpMethod.GET, endpoint);
}

export const SiScoringChoicheQuestions_Get = choicheId => {
    let endpoint = "SrQuestionsChoiche/" + choicheId;
    return HttpRequest(httpMethod.GET, endpoint);
}


// { "SrMacroareaId":1,"SrSectorId":1,"name_it":"bla", "name_en":"blabla" }
export const ChoicheQuestions_Create = (sr_question_id, body) => {
    let endpoint = "SrQuestionsChoiche/" + sr_question_id;
    return HttpRequest(httpMethod.POST, endpoint, body);
}


export const ChoicheQuestions_Delete = id => {
    let endpoint = "SrQuestionsChoiche/" + id;
    return HttpRequest(httpMethod.DELETE, endpoint);
}


export const ChoicheQuestions_Update = (id, body) => {
    let endpoint = "SrQuestionsChoiche/" + id;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}

export const ChoicheQuestions_Add = (fatherQuestionID, body) => {
    let endpoint = "SrQuestionsChoiche/"+ fatherQuestionID;
    return HttpRequest(httpMethod.POST, endpoint, body);
}


// { state: 1 }
export const Evaluations_Status = (id, body) => {
    let endpoint = "SrEvaluations/status/" + id;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const QuestionsStructure_GetAnswer = ratingId => {
    // SrQuestionsStructure / answers / ratingId / tabId / choicheId
    let endpoint = "SrQuestionsStructure/answers/" + ratingId;
    return HttpRequest(httpMethod.GET, endpoint);
}


export const flatQuestion_GET = id => {
    let endpoint = "srquestions/" + id;
    return HttpRequest(httpMethod.GET, endpoint);
}


export const flatQuestion_PUT = (id, body) => {
    let endpoint = "srquestions/question/" + id;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}

export const Questions_GET = id => {
    let endpoint = "srquestions/question/" + id;
    return HttpRequest(httpMethod.GET, endpoint);
}

export const QuestionModel_GET = id => {
    let endpoint = "srquestions/thematicmodel/1";
    return HttpRequest(httpMethod.GET, endpoint);
}

export const QuestionModel_SiScoring_GET = id => {
    let endpoint = "srquestions/thematicmodel/2";
    return HttpRequest(httpMethod.GET, endpoint);
}

export const Question_UPDATE = (id, body) => {
    let endpoint = "srquestions/question/" + id;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}

export const Question_ADD = body => {
    let endpoint = "srquestions/question";
    return HttpRequest(httpMethod.POST, endpoint, body);
}

export const flatQuestionGL_PUT = (id, body) => {
    let endpoint = "srquestions/guideline/" + id;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}

// PUT accounts/language
// { languageId }
export const Language_PUT = id => {
    let endpoint = "accounts/language";
    return HttpRequest(httpMethod.PUT, endpoint, { language_id: id });
}


export const flatAnswer_PUT = (id, body) => {
    let endpoint = "srquestions/choiche/" + id;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}

export const flatAnswer_DELETE = id => {
    let endpoint = "srquestions/choiche/" + id;
    return HttpRequest(httpMethod.DELETE, endpoint);
}


// { "AnswersRequestList":[{ "SrChoicheId":1,"Motivation":"abc","Attachment_id":1 }] }
export const Answers_Put = (body, parametersPath, isSiScoring) => {
    let endpoint = `SrAnswers/${ !!isSiScoring ? "siscoring/" : "" }${ parametersPath }`;
    // let endpoint = "SrAnswers/updateAsync/" + parametersPath;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}




export const SRToolInformative_UploadFile = (file, urlPath, fileName) => {
    let endpoint = [
            "Attachments",
            "upload_gri",
            urlPath
        ]
        .join("/");

    return HttpSingleBlobRequest(endpoint, file, fileName);
}


export const Answer_UploadFile = (file, urlPath, fileName) => {
    let endpoint = [
            "Attachments",
            "upload_sr",
            urlPath
        ]
        .join("/");

    return HttpSingleBlobRequest(endpoint, file, fileName);
}

export const supportUploadFile = (totalUpload) => {
    let endpoint = [
          /*  "supportPage",

        "upload"*/
        "attachments",
        "upload_support"
        ]
        .join("/");

    //let formdata = []
    //if(!!totalUpload) Object.keys(totalUpload).forEach( campo => formdata.append(campo, totalUpload[campo]) );


    return HttpFormAndBlobsRequest(endpoint, totalUpload);
}


export const Answer_DeleteFile = id => {
    return HttpRequest(httpMethod.DELETE, "Attachments/delete_sr/" + id, "");
}

export const SRToolInformative_DeleteFile = id => {
    return HttpRequest(httpMethod.DELETE, "Attachments/delete_gri/" + id, "");
}


export const Question_DownloadFile = (ratingId, fileName) => {
    let endpoint = "Attachments/download_sr/" + ratingId;
    return (
        HttpGetBlobRequest(endpoint)
        .then(data => {
            saveAs(data, fileName);
            return true;
        })
    );

}

export const SRToolInfromativeAttachment_DownloadFile = (ratingId, fileName) => {
    let endpoint = "Attachments/download_gri/" + ratingId;
    return (
        HttpGetBlobRequest(endpoint)
        .then(data => {
            saveAs(data, fileName);
            return true;
        })
    );

}

export const DownloadRatingFile = (ratingId, fileName) => {
    let endpoint = "SrReports/scorePdf/" + ratingId;
    return (
        HttpGetBlobRequest(endpoint)
        .then(data => {
            saveAs(data, fileName);
            return true;
        })
    );

}

export const DownloadRatingQuestionAnswersFile = (ratingId, fileName) => {
    let endpoint = "SrReports/answersPdf/" + ratingId;
    return (
        HttpGetBlobRequest(endpoint)
        .then(data => {
            saveAs(data, fileName);
            return true;
        })
    );

}

export const downloadProfilingDataFile = ratingId => {
    let endpoint = "SrReports/perimeterPdf/" + ratingId;
    return (
        HttpGetBlobRequest(endpoint)
        .then(data => {
            saveAs(data, translate("profiling for evaluation") + ratingId + ".pdf");
            return true;
        })
    );

}

export const downloadAnswersDataFile = ratingId => {
    let endpoint = "SrReports/answersPdf/" + ratingId;
    return (
        HttpGetBlobRequest(endpoint)
        .then(data => {
            saveAs(data, translate("answers for evaluation") + ratingId + ".pdf");
            return true;
        })
    );

}

export const downloadGapAnalysisDataFile = ratingId => {
    let endpoint = "SrReports/gap_analysis/" + ratingId + "/0";
    return (
        HttpGetBlobRequest(endpoint)
        .then(data => {
            saveAs(data, translate("Gap Analysis for evaluation ") + ratingId + ".pdf");
            return true;
        })
    );

}


export const DownloadSGDXMLFile = (ratingId) => {
    let endpoint = "SrReports/xmlSdg/" + ratingId;
    return HttpRequest(httpMethod.GET, endpoint);
}

export const DownloadMaterialityXMLFile = (ratingId) => {
    let endpoint = "SrReports/xmlMateriality/" + ratingId;
    return HttpRequest(httpMethod.GET, endpoint);
}
export const DownloadMaterialityCSVFile = (ratingId) => {
    let endpoint = "SrReports/csvMateriality/" + ratingId;
    return HttpRequest(httpMethod.GET, endpoint);
}

export const DownloadCollectCSVFile = (ratingId, macroarea) => {
    let endpoint = "GriStructure/report_csv/" + ratingId + "/" + macroarea;
    return HttpRequest(httpMethod.GET, endpoint);
}


export const DownloadGapAnalysisCSVFile = (ratingId) => {
    let endpoint = "SrReports/csvGapAnalysis/" + ratingId;
    return HttpRequest(httpMethod.GET, endpoint);
}


export const Attachments_GETEvaulations = ratingId => {
    let endpoint = "Attachments/getSR/" + ratingId + "/0";
    return HttpRequest(httpMethod.GET, endpoint);
}


export const Thematics_Update = (id, body) => {
    let endpoint = "SrThematics/materiality/" + id;
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const SDG_Get = sectorQuestionId => {

    let endpoint = "SrSdg";
    if (!!sectorQuestionId) {
        endpoint = [
            "SrQuestions",
            "sdg",
            "0",
            sectorQuestionId
        ].join("/");
    }

    return HttpRequest(httpMethod.GET, endpoint);
}


export const QuestionSDG_Get = (ratingId, questionId) => {
    return HttpRequest(httpMethod.GET, ["SrQuestions", "sdg", ratingId, questionId].join("/"));
}

/**
 * @deprecated
 * @param ratingId
 * @returns {Promise<*|SimpleCacheConfigurator.never>}
 * @constructor
 */
export const RatingInfo_Get = ratingId => {
    return HttpRequest(httpMethod.GET, ["SrAnswers", "get_completed", ratingId].join("/"));
}


export const QuestionSDG_Put = (ratingId, questionId, body) => {
    // PUT SrSdg/ratingId/questionId
    let endpoint = ["SrSdg", ratingId, questionId].join("/");
    return HttpRequest(httpMethod.PUT, endpoint, body);
}


export const SdgScore_Get = (SrEvalutationId) => {
    return HttpRequest(httpMethod.GET, ["SrReports", "sdg", SrEvalutationId].join("/"));
}


export const Score_Get = (SrEvalutationId, MacroareaId) => {
    return HttpRequest(httpMethod.GET, ["SrReports", "modalScore", SrEvalutationId, MacroareaId].join("/"));
}


export const CriteriaScore_Get = (SrEvalutationId) => {
    return HttpRequest(httpMethod.GET, ["SrReports", "criteria", SrEvalutationId].join("/"));
}

export const QuestionReport_Get = (SrEvalutationId) => {
    return HttpRequest(httpMethod.GET, ["SrQuestionsStructure", "progress", SrEvalutationId].join("/"));
}

export const informativeStateReport_Get = (SrEvalutationId) => {
    return HttpRequest(httpMethod.GET, ["GriReports", "informativeState", SrEvalutationId].join("/"));
}

export const MaterialityReport_Get = (SrEvalutationId) => {
    return HttpRequest(httpMethod.GET, ["SrReports", "materiality", SrEvalutationId].join("/"));
}

export const SendNcQuestions_Get = (SrEvalutationId) => {
    return HttpRequest(httpMethod.GET, ["Accounts", "ncquestionssendemail", SrEvalutationId].join("/"));
}
export const PreviewNcQuestions_Get = (SrEvalutationId) => {
    return HttpRequest(httpMethod.GET, ["Accounts", "ncquestionssendemail_preview", SrEvalutationId].join("/"));
}

export const SendAttachmentsQuestions_Get = (SrEvalutationId) => {
    return HttpRequest(httpMethod.GET, ["Accounts", "attachmentsquestionssendemail", SrEvalutationId].join("/"));
}
export const PreviewAttachmentsQuestions_Get = (SrEvalutationId) => {
    return HttpRequest(httpMethod.GET, ["Accounts", "attachmentsquestionssendemail_preview", SrEvalutationId].join("/"));
}

export const SendNewAttachmentsInserted_Get = (SrEvalutationId) => {
    return HttpRequest(httpMethod.GET, ["Accounts", "infoattachmenstevaluationsendemail", SrEvalutationId].join("/"));
}
export const PreviewNewAttachmentsInserted_Get = (SrEvalutationId) => {
    return HttpRequest(httpMethod.GET, ["Accounts", "infoattachmenstevaluationsendemail_preview", SrEvalutationId].join("/"));
}

export const EvaluationStatesReport_Get = (SrEvalutationId) => {
    return HttpRequest(httpMethod.GET, ["SrEvaluations", "get_states", SrEvalutationId].join("/"));
}


export const themesChart = (SrEvalutationId, MacroareaId) => {
    return HttpRequest(httpMethod.GET, ["SrReports", "graphScore", SrEvalutationId, MacroareaId].join("/"));
}

export const criteriaChart = (SrEvalutationId) => {
    return HttpRequest(httpMethod.GET, ["SrReports", "criteria", SrEvalutationId].join("/"));
}

export const getAnswerProgress = ratingId => {
    return HttpRequest(httpMethod.GET, ["SrQuestionsStructure", "progress", ratingId].join("/"));
}


export const Supportsendemail_Post = body => {
    let endpoint = "accounts/supportsendemail";
    return HttpRequest(httpMethod.POST, endpoint, body);
}


export const QuestionNote_GET = (ratingId, qId) => {
    return HttpRequest(httpMethod.GET, ["SrQuestionsNote", ratingId, qId].join("/"));
}

export const AdminQuestionNote_GET = (ratingId, qId) => {
    return HttpRequest(httpMethod.GET, ["SrQuestionsNote", "noteinternal", ratingId, qId].join("/"));
}

export const InformativeNote_GET = (ratingId, qId) => {
    return HttpRequest(httpMethod.GET, ["griinformativesnote", ratingId, qId].join("/"));
}


export const evaluationStatus_GET = eid => {
    return HttpRequest(httpMethod.GET, ["srevaluations", "get_currentStates", eid].join("/"));
}



export const QuestionNote_SAVE = (ratingId, qId, note) => {
    let endpoint = ["SrQuestionsNote", ratingId, qId].join("/");
    return HttpRequest(httpMethod.PUT, endpoint, note);
}

export const AdminQuestionNote_SAVE = (ratingId, qId, note) => {
    let endpoint = ["SrQuestionsNote", "noteinternal", ratingId, qId].join("/");
    return HttpRequest(httpMethod.PUT, endpoint, note);
}

export const InformativeNote_SAVE = (ratingId, qId, note) => {
    let endpoint = ["griinformativesnote", ratingId, qId].join("/");
    return HttpRequest(httpMethod.PUT, endpoint, note);
}


export const SRToolCollectPMAuthorization = (cid, data) => {
    let endpoint = "gritimings/setIsPmAuthorized/" + cid;
    return HttpRequest(httpMethod.PUT, endpoint, data);
}



export const SRToolInformativeAttachments = (collectId, informativeId) => {
    let endpoint = ["Attachments", "getGRI", collectId, informativeId].join("/");

    return (
        new Promise((success, fail) => {
            setTimeout(
                function() {
                    success();
                },
                1000
            );
        })
        .then(() => {
            return HttpRequest(httpMethod.GET, endpoint);
        })
    );

}

export const sendEmailModifyProfilation = (idValutation, eText) => {
    let endpoint = "Accounts/profilingsupportsendemail/" + idValutation;
    return HttpRequest(httpMethod.POST, endpoint, eText);
}

export const validAllAuditQuestions = (idValutation) => {
    let endpoint = "SrEvaluations/validateallquestions/" + idValutation;
    return HttpRequest(httpMethod.PUT, endpoint);
}


/*

export const CriteriaScore_Get = (SrEvalutationId) => {
	let endpoint = ["SrReports/criteria", SrEvalutationId].join("/");
	return (


		new Promise((resolve, reject) => {

			resolve();
		})

		.then( () => {

			let requestOptions = {
				method: httpMethod.GET,
				headers: {
					"Content-Type": "application/json"
					// Origin: "8.8.8.8"
				},
				redirect: "follow",
				credentials: "include"
			};


			return (
				Promise.race([
					httpTimer.start(),
					fetch(HOST + API_PORT + API_ENDPOINT + "/" + endpoint, requestOptions)
						.then(result => result.json())
						.then(result => {
							if (!result.status) {
								throw (
									Object.keys(errorMessage)
										.map(errorKind => errorMessage[errorKind])
										.filter(e => e.code === result.code)
										.map(e => e.msg)
										.reduce(getLastElementOr, result.message)
								);
								// throw result.message;
							}
							return result;
						})
						.then(result => {
							return result;
						})
						.catch(error => {
							errorlog("HTTP");
							return Promise.reject(error);
						})
				])
			)
		})
	)


}





export const SdgScore_Get = (SrEvalutationId) => {
	let endpoint = ["SrReports/sdg", SrEvalutationId].join("/");
	return (


		new Promise((resolve, reject) => {

			resolve();
		})

		.then( () => {


	let requestOptions = {
		method: httpMethod.GET,
		headers: {
			"Content-Type": "application/json"
			// Origin: "8.8.8.8"
		},
		redirect: "follow",
		credentials: "include"
	};


	return (
		Promise.race([
			httpTimer.start(),
			fetch(HOST + API_PORT + API_ENDPOINT + "/" + endpoint, requestOptions)
				.then(result => result.json())
				.then(result => {
					if (!result.status) {
						throw (
							Object.keys(errorMessage)
								.map(errorKind => errorMessage[errorKind])
								.filter(e => e.code === result.code)
								.map(e => e.msg)
								.reduce(getLastElementOr, result.message)
						);
						// throw result.message;
					}
					return result;
				})
				.then(result => {
					return result;
				})
				.catch(error => {
					errorlog("HTTP");
					return Promise.reject(error);
				})
		])
	)
		})
	)


}





export const _Score_Get = (SrEvalutationId, MacroareaId) => {
	let endpoint = ["SrReports", "score", SrEvalutationId, MacroareaId].join("/");
	return (


		new Promise((resolve, reject) => {

			resolve();
		})

		.then( () => {


	let requestOptions = {
		method: httpMethod.GET,
		headers: {
			"Content-Type": "application/json"
			// Origin: "8.8.8.8"
		},
		redirect: "follow",
		credentials: "include"
	};


	return (
		Promise.race([
			httpTimer.start(),
			fetch(HOST + API_PORT + API_ENDPOINT + "/" + endpoint, requestOptions)
				.then(result => result.json())
				.then(result => {
					if (!result.status) {
						throw (
							Object.keys(errorMessage)
								.map(errorKind => errorMessage[errorKind])
								.filter(e => e.code === result.code)
								.map(e => e.msg)
								.reduce(getLastElementOr, result.message)
						);
						// throw result.message;
					}
					return result;
				})
				.then(result => {
					return result;
				})
				.catch(error => {
					errorlog("HTTP");
					return Promise.reject(error);
				})
		])
	)
		})
	)


}
*/


export const QuestionAudit = (ratingId, questionId, payload) => {
    let endpoint = ["SrQuestions", "status", ratingId, questionId].join("/");
    return HttpRequest(httpMethod.PUT, endpoint, payload);
}


export const RatingStatus_Get = id => {
    let endpoint = ["SrEvaluations", "get_states", id].join("/");
    return HttpRequest(httpMethod.GET, endpoint);
}

export const Attachment_Set = (attachmentId, value) => {
    let endpoint = ["Attachments", "set_approved", attachmentId].join("/");
    return HttpRequest(httpMethod.PUT, endpoint, value);
}

export const PdfParameters_Get = ratingId => {
    let endpoint = ["SrPdfParameters", ratingId].join("/");
    return HttpRequest(httpMethod.GET, endpoint);
}

export const PdfParameters_Put = (ratingId, body) => {
    let endpoint = ["SrPdfParameters", ratingId].join("/");
    return HttpRequest(httpMethod.PUT, endpoint, body);
}

export const PerimeterData_Get = ratingId => {
    let endpoint = ["SrQuestionsStructure", "perimeter", ratingId].join("/");
    return HttpRequest(httpMethod.GET, endpoint);
}

export const PerimeterData_Put = (ratingId, questionId, body) => {
    let endpoint = ["SrAnswers", "perimeter", ratingId, questionId].join("/");
    return HttpRequest(httpMethod.PUT, endpoint, body);
}

export const GetVersionHistory = (idtool) => {
    let endpoint = ["versioning/"+ idtool]
    return HttpRequest(httpMethod.GET, endpoint);
}

export const AddRowHistoryVersion = (obj) => {
    let endpoint = ["versioning"]
    return HttpRequest(httpMethod.POST, endpoint, obj);
}

export const ModifyRowHistoryVersion = (obj) => {
    let endpoint = ["versioning"]
    return HttpRequest(httpMethod.PATCH, endpoint, obj);
}

export const QuestionHistory_Get = (ratingId, qiestionId) => {
    let endpoint = ["SrQuestions", "history", ratingId, qiestionId].join("/");
    return HttpRequest(httpMethod.GET, endpoint);
}


export const Provinces = () => {
    let endpoint = ["Companies", "provinces"].join("/");
    return HttpRequest(httpMethod.GET, endpoint);
}

export const saveCompanyData = data => {
    let endpoint = ["Companies"].join("/");
    return HttpRequest(httpMethod.POST, endpoint, data);
}

export const updateCompanyData = (data, cid) => {
    let endpoint = ["Companies", "edit", cid].join("/");
    return HttpRequest(httpMethod.PUT, endpoint, data);
}

export const RequirementValues = (thematic_id, chapter_id, informative_id, requirement_id, subrequirement, subsubrequirement, type, id, timing_select, graphType_select, collectionCompare_select, chartId, collectId) => {
    let endpoint = [
        "GriReports",
        "requirementValues",
        chartId || 1,
        thematic_id || 0,
        chapter_id || 0,
        informative_id || 0,
        requirement_id || 0,
        subrequirement || 0,
        subsubrequirement || 0,
        type || 0,
        id || 0,
        timing_select || 0, // inutilizzato
        graphType_select || 0,
        collectionCompare_select || 0, // comparazione
        collectId || 0 // raccolta
    ].join("/");
    // debugger;
    return HttpRequest(httpMethod.GET, endpoint);
}

export const RequirementFilter = (level, id) => {
    let endpoint = ["GriRequirements", level, id].join("/");
    return HttpRequest(httpMethod.GET, endpoint);
}


export const getDashboardInit = () => {
    let endpoint = "grireports/dashboardinit";
    return HttpRequest(httpMethod.GET, endpoint);
}


export const measureUnitListGet = collectId => {
    let endpoint = "GriUnitMeasurementsTimings/" + collectId;
    return HttpRequest(httpMethod.GET, endpoint);
};


export const confTreeMeasureUnitPost = (collectId, measureUnitFamilyId, measureUnitId) => {
    let endpoint = [
        "GriUnitMeasurementsTimings",
        collectId || 0,
        measureUnitFamilyId || 0,
        measureUnitId || 0
    ].join("/");
    return HttpRequest(httpMethod.PUT, endpoint);
}



export const organizzation_GET = () => {
    let endpoint = "leadingcompanies";
    return HttpRequest(httpMethod.GET, endpoint);
}

export const organizzation_POST = oData => {
    let endpoint = "leadingcompanies";
    return HttpRequest(httpMethod.POST, endpoint, oData);
}

export const organizzation_PUT = (oid, oData) => {
    let endpoint = "leadingcompanies/" + oid;
    return HttpRequest(httpMethod.PUT, endpoint, oData);
}

export const organizzation_DELETE = oid => {
    let endpoint = "leadingcompanies/" + oid;
    return HttpRequest(httpMethod.DELETE, endpoint);
}


export const company_organizzation_DELETE = oid => {
    let endpoint = "companies/" + oid;
    return HttpRequest(httpMethod.DELETE, endpoint);
}




export const SuperAdminDashboard_GET = (year, macroArea) => {
    let endpoint = "GriReports/sadashboard/" + (year || 0) + "/" + macroArea;
    return HttpRequest(httpMethod.GET, endpoint);
}

export const SuperAdminDashboard_SET = (chartId, year, payload) => {
    let endpoint = ["GriReports", "setFilter", chartId, year].join("/");
    return HttpRequest(httpMethod.POST, endpoint, payload);
}

export const SuperAdminDashboard_DELETE = graphID => {
    let endpoint = ["GriReports", graphID].join("/");
    return HttpRequest(httpMethod.DELETE, endpoint);
}

export const ValidAllAudit = id => {
    let endpoint = "SrEvaluations/validateallquestions/" + id;
    return HttpRequest(httpMethod.PUT, endpoint);

}

export const GetSiRatingQuestionInfo = id => {
    let endpoint = "SrQuestions/info/" + id;
    return HttpRequest(httpMethod.GET, endpoint);

}

export const getReport = (id, number) => {
    let endpoint = "GriReports/informativeValuedReport/" + id + "/" + number;
    return HttpRequest(httpMethod.GET, endpoint);
}

export const getGlossary = () => {
    let endpoint = "GriStructure/glossary";
    return HttpRequest(httpMethod.GET, endpoint)
}

export const DeleteCompany = (id) => {
    let endpoint = "Companies/" + id;
    return HttpRequest(httpMethod.DELETE, endpoint)
}

export const sectorEvaluationsQuestionAssociated = id => {
    let endpoint = "SrQuestions/sectorQuestionEvaluationsAssociated/" + id + "/0";
    return HttpRequest(httpMethod.GET, endpoint)
}

export const sectorQuestionEvaluationsAssociated = id => {
    let endpoint = "SrQuestions/sectorQuestionEvaluationsAssociated/0/" + id;
    return HttpRequest(httpMethod.GET, endpoint)
}


export const getSectorQuestionEvaluationsLink = (sectorQuestionID, evaluationID) => HttpRequest(httpMethod.GET, `SrQuestions/sectorQuestionEvaluationsAssociated/${ sectorQuestionID }/${ evaluationID }`);

export const associationEvaluationSectorQuestion_POST = ( secID, evID, IsAssociated ) => {
    let endpoint = `SrQuestions/sectorQuestionEvaluationsAssociated`;
    return HttpRequest(httpMethod.POST, endpoint, {
        Evaluation_id: evID,
        Question_id: secID,
        IsAssociated: IsAssociated
    })
}

export const GetAssociatedSector = id => {
    let endpoint = "SrEvaluations/sectorAssociated/" + id;
    return HttpRequest(httpMethod.GET, endpoint)
}

export const SiScoringUserManual_DOWNLOAD = (lang) => {

    let fileName = "siscoring_manualeutente";
    switch (lang) {
        case 'IT-it':
            fileName = "siscoring_manualeutente";
            break;
        case 'EN-gb':
            fileName = "siscoring_userguide";
            break;
        case 'EN-us':
            fileName = "siscoring_userguide";
            break;
        case 'DE-de':
            fileName = "siscoring_userguide";
            break;
        case 'FR-fr':
            fileName = "siscoring_userguide";
            break;
        case 'ES-es':
            fileName = "siscoring_userguide";
            break;
        // <-------- altre lingue
        default:
            fileName = "siscoring_manualeutente";
            break
    }

    let endpoint = `assets/documents/user_guide/${ fileName }.pdf`;
    // return HttpGetBlobRequest(endpoint);
    return (
        fetch(`${ PRODUCTION_HOST }/${ endpoint }`)
            .then(result => result.blob())
            .catch(error => {
                errorlog("HttpRequest error", error);
                return Promise.reject(error);
            })
    );
}

export const sendExternalAuthToken = (token: string) => {
    let endpoint = `accounts/authenticateSpoiler`;
    // return HttpRequest(httpMethod.GET, endpoint);
    return HttpRequest(httpMethod.POST, endpoint, {Code: token});
}


// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------



export const errRemoteLog = data => {


    showlog("log error in remote", data);
    beautifyLog(data);

    return (
        Promise.all([
            // HttpRequest(httpMethod.POST, REMOTE_LOGS_ENDPOINT, data)
            //     .then(result => {
            //         return result;
            //     }),
            fetch(
                LOGS_PORTALADDRESS, {
                    method: httpMethod.POST,
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": LOSG_PORTAL_TOKEN
                    },
                    body: JSON.stringify(data)
                }
            )
            .then(result => result.json())
            .then(result => {
                return result;
            })
        ])
        .then(esultList => {
            return { status: STATUS_SUCCESS }
        })
        .catch(e => {
            return Promise.reject(e);
        })
    );


    // return HttpRequest(httpMethod.POST, REMOTE_LOGS_ENDPOINT, data);
}
